<template>
    <Dialog :dialog="dlg">
        <template v-slot:toolbar-left>
            
            <ToolbarField label="Status">
                <ProjectPhaseChip v-model="model.id_project_phase"></ProjectPhaseChip>
            </ToolbarField>

            <ToolbarField class="ml-8" label="Projectnummer">
                <span class="text-primary">{{`${model.pro_number||'-'}`}}</span>
            </ToolbarField>

            <ToolbarField class="ml-8" label="Relatie">
                <span class="text-primary">{{model.rel_name}}</span>
            </ToolbarField>

        </template>
        <template v-slot:toolbar-menu>

        </template>        

        <template v-slot:nu_even_niet toolbar-right>

           <ActionButton ml dropdown>
              <template v-slot:dropdown>
                    <ActionMenuItem no-icon action="salesinvoice.create" no-execute  @click="onCreateInvoice">Factuur</ActionMenuItem> 
                    <ActionMenuItem no-icon action="tender.create" no-execute  @click="onCreateTender">Of   rte</ActionMenuItem> 
              </template>
              Voeg toe
           </ActionButton>

        </template>

        <template v-slot:tabs-left>
            <v-tabs dense v-model="dlg.tabsTab">        
                <v-tab>Gegevens</v-tab>                
                <v-tab v-if="true" :disabled="model.isNew||model.isLoading">Hoofdstukken</v-tab>
                <v-tab :disabled="model.isNew||model.isLoading">Dossier</v-tab>
                <v-tab :disabled="model.isNew||model.isLoading">Meer / minderwerk</v-tab>                
                <v-tab :disabled="model.isNew||model.isLoading">Overige kosten</v-tab>                
                <v-tab :disabled="model.isNew||model.isLoading">Limieten</v-tab>                
                

                <v-tab v-if="0">Model</v-tab>                
                <v-tab v-if="0">Financieel</v-tab>                
                <v-tab v-if="0">Limieten</v-tab>                
                <v-tab v-if="0">Medewerkers</v-tab>                
                <v-tab v-if="0">Planning</v-tab>                
            </v-tabs>
        </template>        

        <v-tabs-items class="relation-tabs mt-0" v-model="dlg.tab">                
            <v-tab-item class="project-header" transition="none" reverse-transition="none">                        
                <v-form ref="formHeader" lazy-validation>
                    <TabHeader :model="model"/>
                </v-form>
            </v-tab-item>
            <v-tab-item v-if="true" class="project-budget-1" transition="none" reverse-transition="none">                        
                <v-form ref="formBudget1" lazy-validation>
                    <TabChapters :model="model"/>
                </v-form>
            </v-tab-item>
            <v-tab-item class="project-dossier" transition="none" reverse-transition="none">                        
                <TabDossier :model="model" :parentTabActive="dlg.tabsTab==nTabDossier"/>
            </v-tab-item>
            <v-tab-item class="project-extrawork" transition="none" reverse-transition="none">                        
                <v-card dense>
                    <v-card-text class="pa-0">
                        <TabExtraWork :model="model"/>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item class="project-othercost" transition="none" reverse-transition="none">                        
                <v-card dense>
                    <v-card-text class="pa-0">
                        <TabOtherCost :model="model"/>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item class="project-limits" transition="none" reverse-transition="none">                        
                <v-card dense>
                    <v-card-text class="pa-0">
                        <TabLimits :model="model"/>
                    </v-card-text>
                </v-card>
            </v-tab-item>

        </v-tabs-items>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/project/project'
    import ProjectPhaseChip from '@/ui/controls/ProjectPhaseChip'

    import Dialog from '@shared/ui/dialogs/ActionDialogModal'
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { ref, watch } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import ToolbarField from '@shared/ui/dialogs/ToolbarField'
    
    import TabHeader from './TabHeader'
    import TabExtraWork from './TabExtraWork'
    import TabOtherCost from './TabOtherCost'
    import TabLimits from './TabLimits'
    import TabDossier from './dossier/TabDossier'    
    import TabChapters from './chapters/TabChapters'
    import feature from '@app/features'
    import string from '@lib/string'

    const formHeader = ref(null); 
    const formBudget = ref(null); 

    let model = useModel();

    const nTabDossier = 2; 
    const nTabExtrawork = 3; 
    const nTabOtherCost = 4; 

    // Ok. 
    // After first having worked out a difficult scenario where every saved object was examined against the data in our object structure
    // and in the different tabs of this dialog I have realized that this can be done much easier. 
    //  
    // The onModelSaved event is only called when the project dialog is open. 
    // That means that any invoice, purchase invoice, other cost or extra work which is saved when we are open 
    // must belong to this project as it is opened from our dialog. 
    // Therefore, we can just catch the saved events for the models we are interested in and without further check 'mark' us as dirty.
    // 
    // As we do not need to verify the relation keys, we can use the same handler for the save and removed events. 
    function onModelSaved(type, data, a,b,c) {
        var typesOfInterest = [
            'project_othercosts', 
            'project_additionalwork', 
            'purchaseinvoice', 
            'salesinvoice', 
            'salesorder',
            'salesinvoice_send', 
        ];
        if (string.isInRange(type, typesOfInterest)) {
            // We just mark the result as dirty so that the lower level components 
            // can just check the model for dirtyness when the 'Result' tab is activated and we don't have to know the internals.
            model.isResultDirty = true;
        }
    }
    let dlg = ref(
        new clsDialog("project", "Project", model, [formHeader, formBudget], 
        {
            setKeyOnOpen: true,
            noteType: "project_note",
            flagType: "project_flag",
            attachmentType: "project_attachment",

            keepOpen: true,
            onModelSaved: onModelSaved,   // The logic ... 
            onModelRemoved: onModelSaved, // ... is the same
            xsm:12,xlg:10,xxl:8
        }
    )).value; 

    async function onCreateInvoice() {     
     alert('NYI')
    }
    async function onCreateTender() {
        alert('NYI')
    }

</script>

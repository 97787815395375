<template>
    <span class="toggle-button single">
        <v-btn  block :disabled="compDisabled||compSkeleton"
            outlined
            @click="dvalue=!dvalue"
            :color="dvalue?'primary':''"
            :class="dvalue?'selected':'unselected'" >
            <slot></slot>      
        </v-btn> 
    </span>
</template>

<script setup>
// Usage: 
// import ToggleButton from '@controls/buttons/ToggleButton'
// <ToggleButton :model="model" v-model="model.mon">Ma</ToggleButton>
import {computed, ref} from 'vue'
        
    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [Boolean]
        },
        model: {
            type: [Object]
        }

    })

    const emit = defineEmits(['input'])
    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

</script>

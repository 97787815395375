<template>    

    <span style="top:-2px;" :class="compClass">
        <IconMenu max-width="600" nudge-bottom="12" :icon="compIcon" :color="compColor"> 
            <template v-slot:dropdown>
                <v-card><v-card-text>                    
                    <slot></slot>

                </v-card-text></v-card>
            </template>
        </IconMenu>
    </span>

</template>

<script setup>

/*
    A Tip-dropdown menu.
    Usage: 
    
        import TipMenu from "@controls/menu/TipMenu.vue";

        <td>Balans grootboek
            <TipMenu>
                <p>De standaard balans grootboeken voor inkoop - en verkoop.</p>
                <p>U kunt dit grootboek in Twinfield overschrijven door een standaard balans grootboek aan de relatie toe te kennen.</p> 
            </TipMenu>
        </td>
        
*/
import IconMenu from "@controls/menu/IconMenu.vue";
import {computed, ref} from 'vue'

    const props = defineProps({
        right: {
            type: [Boolean],
            default: true
        },
        left: {
            type: [Boolean],
            default: false
        },
        cls: {
            type: [String],
            default: "d-inline-block"
        },
        warning: {
            type: [Boolean]
        },
        color: {
            type: [String],
            default: ""
        },
        ml: {
            type: [Boolean]
        },
    })

    const compClass = computed({
        get() {
            var cls = "d-inline-block position-relative";
            if (!props.left) {
                cls = `${cls} float-right`;                
            }
            if (props.ml) {
                cls = `${cls} ml-1`;                                
            }
            return cls;
        }
    })
    const compIcon = computed({
        get() {
            return props.warning ? "warning" : "help"
        }
    })
    const compColor = computed({
        get() {
            if (!!props.color) {
                return props.color;
            }
            return props.warning ? "warning" : "primary"
        }
    })

</script>

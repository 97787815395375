<template>
    <v-form ref="form">
        <v-card class="bg-card mb-2">
            <v-card-text>
                <FormRow col-label="1" label="Dag">            
                    <span class="w-3 d-inline-block">     <ToggleButton :model="model" v-model="model.cline.mon"><span class="text-bold">Ma</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.cline.fmt_date_mon }}</span></ToggleButton></span>
                    <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.cline.tue"><span class="text-bold">Di</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.cline.fmt_date_tue }}</span></ToggleButton></span>
                    <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.cline.wed"><span class="text-bold">Wo</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.cline.fmt_date_wed }}</span></ToggleButton></span>
                    <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.cline.thu"><span class="text-bold">Do</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.cline.fmt_date_thu }}</span></ToggleButton></span>
                    <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.cline.fri"><span class="text-bold">Vr</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.cline.fmt_date_fri }}</span></ToggleButton></span>
                    <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.cline.sat"><span class="text-bold">Za</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.cline.fmt_date_sat }}</span></ToggleButton></span>
                    <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.cline.sun"><span class="text-bold">Zo</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.cline.fmt_date_sun }}</span></ToggleButton></span>
                </FormRow>
                <v-row dense>
                    <v-col sm="2" xl="1"><div class="v-top"><label></label></div></v-col>
                    <v-col sm="10" lg="4" xl="3">
                        <Checkbox switcher :model="model" v-model="model.cline.specify_start_end_time" label="Specificeer start- end eindtijd"></Checkbox>
                    </v-col>
                </v-row>
                <v-row dense v-if="model.cline.specify_start_end_time">
                    <v-col sm="2" xl="1"><div class="v-top"><label>Start- en eind tijd</label></div></v-col>
                    <v-col sm="10" lg="4" xl="3">
                        <v-row dense>
                            <v-col>
                                <TimeField :slim-fit="false" hm :model="model" v-model="model.cline.hr_start_time"></TimeField>
                            </v-col>
                            <v-col>
                                <TimeField :slim-fit="false" hm :model="model" v-model="model.cline.hr_end_time"></TimeField>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col sm="2" xl="1"><div class="v-top"><label>Gewerkte uren</label></div></v-col>
                    <v-col sm="10" lg="4" xl="3">
                        <v-row dense>
                            <v-col>
                                <TimeField :slim-fit="false" :model="model" v-model="model.cline.hr_total_minutes"></TimeField>
                            </v-col>
                            <v-col>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row dense v-if="!model.cline.specify_start_end_time">
                    <v-col sm="2" xl="1"><div class="v-top"><label>Tarief</label></div></v-col>
                    <v-col sm="10" lg="4" xl="3">
                        <TariffCombo :rules="model.cline.specify_start_end_time?[]:model.rules.mandatory" :model="model" v-model="model.cline.id_tariff"></TariffCombo>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col sm="2" xl="1"><div class="v-top"><label>Urentype</label></div></v-col>
                    <v-col sm="10" lg="4" xl="3">
                        <HourTypeCombo :model="model" :rules="model.rules.mandatory" v-model="model.cline.id_hour_type"></HourTypeCombo>                            
                    </v-col>
                </v-row>
                <v-row v-if="feature.canProject" dense>
                    <v-col sm="2" xl="1"><div class="v-top"><label>Project</label></div></v-col>
                    <v-col sm="10" lg="4" xl="3">
                        <ProjectPicker :disabled="!model.cline.isForProject" :rules="model.cline.isForProject?model.rules.mandatory:[]" :filter="{'selectfor':'hours'}" :fieldMap="projectFieldMap" :model='model' :valueModel="model.cline" v-model='model.cline.id_project'></ProjectPicker>
                    </v-col>
                </v-row>
                <v-row v-if="feature.canProject" dense>
                    <v-col sm="2" xl="1"><div class="v-top"><label>Opmerking</label></div></v-col>
                    <v-col sm="10" lg="4" xl="3">
                        <TextField max-length="255" placeholder="Opmerking" :model="model" v-model="model.cline.hr_remark"></TextField>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider class="xmb-1"></v-divider> 
            <v-card-text>
                <v-row dense>
                    <v-col sm="auto">
                        <ActionButton :model="model" main primary @click='()=>addLine()'>Voeg regel toe</ActionButton>
                    </v-col>
                    <v-col>
                        <AlertArea v-if="model.hasEmptyEmployeeTariff" warning icon cls="ml-4 pa-1 d-inline-block px-4" outlined>
                                Bij een of meer van de gebruikte tarieven is voor de medewerker geen bedrag gespecificeerd. 
                        </AlertArea>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-form>
</template>



<script setup>

    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import ProjectPicker from '@controls/picker/Project'
    import feature from '@app/features'
    import Checkbox from '@controls/check/Checkbox.vue'
    import HourTypeCombo from '@controls/combo/HourType'
    import TariffCombo from '@controls/combo/Tariff'
    import TimeField from '@controls/input/TimeField'
    import ToggleButton from '@controls/buttons/ToggleButton'
    import ActionButton from '@controls/buttons/ActionButton'
    import AlertArea from '@shared/ui/markup/AlertArea'

    import {ref} from 'vue'

    // Our fields which come from projects. We want them overridden when a project is selected.
    let projectFieldMap = {pro_reference: 'ord_reference', pro_name: 'ord_name', pro_number: 'pro_number'};

    const form = ref();

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    function addLine() {
        if (!form.value.validate()) {
            return;
        }
        props.model.addLine();
    }

</script>

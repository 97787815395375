<template>
    <Dialog :dialog="dlg">
        <v-row>
            <v-col sm="3">
                Video
            </v-col>
            <v-col sm="9">
                <v-form ref="form" lazy-validation>
                    <div style="width:100%;min-height:600px;">
                    <iframe style="width:100%;min-height:600px;" class='ql-video' frameborder='0' allowfullscreen='true' src='https://www.youtube.com/embed/d4rU_ZzJihk'>

                    </iframe>
                </div>
                    <div v-if="false">
                        <TextArea :rows="12" :model="model" dusk="note" :skeleton="model.isDataLoading" placeholder="Notitie" v-model="model.note"></TextArea>
                    </div>
                </v-form>

            </v-col>
        </v-row>
    </Dialog>
</template>



<script setup>
    import useModel from '@/models/help'
    import TextArea from '@controls/input/TextArea'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("help", "Help", model, [form], 
            {
                maxWidth:1100,
                noSubTitle: true,
                noActions: true
            }
        )).value;

</script>

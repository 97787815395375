<template>

    <ActionCombo :noClearable="noClearable" :placeholder="placeholder" :model="model" :skeleton="skeleton" :disabled="disabled" :rules="rules" v-model="dvalue" :items="items"></ActionCombo>

</template>

<script setup>

    // import Tariff from '@controls/combo/Tariff'
    // <Tariff :model="model" :rules="model.rules.mandatory" v-model="model.id_tariff"></Tariff>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'Tariefsoort'
        },
        model: {
            type: [Object]
        }

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {computed} from 'vue'
    import {tariff as list} from '@app/list';
    
    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const items = computed({
        get() {
            return list.listActual(dvalue.value) ||[]
        },
    })

</script>

<template>
        <v-card class="bg-card">
            <v-card-text>
                <v-row dense>
                    <v-col>
                        <ToggleButtonGroup dusk="type" col-auto :disabled="model.isStatusAtLeastAccepted" :model="model" v-model="model.type" :options="[{id: 'invoice', name: 'Factuur'}, {id: 'pin', name: 'Pinbon'}, {id: 'cash', name: 'Kasbon'}]"/>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider class="my-0" ></v-divider>
            <v-card-text v-if="model.isTypeInvoice">
                <v-row dense>
                    <v-col class="col-auto">
                        <ToggleButtonGroup dusk="is_paid" col-auto bool :model="model" v-model="model.is_paid" :options="[{id: '0', name: 'Niet betaald'}, {id: '1', name: 'Betaald'}]"/>
                    </v-col>
                    <v-col class="inline-flex v-center">
                        <Checkbox dusk="incasso" :model="model" v-model="model.incasso" label="Incasso factuur"/>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider class="my-0" ></v-divider>
            <v-card-text>
                <v-row dense>
                    <v-col sm="12" xl="6" class="pr-xl-4">
                        <v-row dense v-if="model.isTypeInvoice">
                            <v-col class="pr-xl-4">
                                <FormRow col-label="4" label="Relatie">    
                                    <RelationCombo dusk="id_relation" purchase :disabled="model.isStatusAtLeastAccepted" :model="model" :rules="model.rules.id_relation" v-model="model.id_relation"></RelationCombo>
                                </FormRow>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="model.isTypeInvoice">
                            <v-col class="pr-xl-4">
                                <FormRow col-label="4" label="IBAN">    
                                    <RelationIBAN dusk="iban" :id_relation="model.id_relation" :model="model" :rules="model.rules.iban" v-model="model.iban"></RelationIBAN>
                                </FormRow>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="pr-xl-4">
                                <FormRow col-label="4" label="Bedrag incl. BTW">    
                                    <v-row dense>    
                                        <v-col class="col-4">
                                            <CurrencyCombo dusk="currency" :disabled="model.isStatusAtLeastAccepted" no-clearable :model="model" :rules="model.rules.crnc_code" v-model="model.crnc_code"></CurrencyCombo>
                                        </v-col>
                                        <v-col>
                                            <NumberField dusk="total_payable" :disabled="model.isStatusAtLeastAccepted" :model="model" amount v-model="model.total_payable" :currency="model.crnc_code"></NumberField>
                                        </v-col>
                                    </v-row>
                                </FormRow>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="!model.isTypeInvoice" >
                            <v-col class="pr-xl-4">
                                <FormRow col-label="4" label="Omschrijving" subLabel="Optioneel">            
                                    <TextField dusk="note" :disabled="model.isStatusAtLeastAccepted" placeholder="Lunchkosten" max-length="255" :model="model" :rules="model.rules.note" v-model="model.note"></TextField>
                                </FormRow>
                            </v-col>
                        </v-row>

                        <v-row dense v-if="showGAccount && model.isTypeInvoice" >
                            <v-col class="pr-xl-4">
                                <FormRow col-label="4" label="Naar G-rekening">
                                    <v-row dense>    
                                        <v-col class="col-8">
                                            <RelationIBAN dusk="ibang" g :disabled="model.isStatusAtLeastAccepted" :id_relation="model.id_relation" :model="model" :rules="model.rules.ibang" v-model="model.ibang"></RelationIBAN>
                                        </v-col>
                                        <v-col>
                                            <NumberField dusk="g_amount" :disabled="model.isStatusAtLeastAccepted" amount :model="model" v-model="model.g_amount" :currency="model.crnc_code"></NumberField>
                                        </v-col>
                                    </v-row>
                                </FormRow>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="model.isTypeInvoice && feature.canCreditRestriction">
                            <v-col class="pr-xl-4">
                                <FormRow col-label="4" label="Betalingskorting">    
                                    <v-row dense>    
                                        <v-col class="col-8">

                                            <Creditrestriction dusk="id_credit_restriction" :disabled="model.isStatusAtLeastAccepted" :model="model" :rules="model.rules.id_credit_restriction" v-model="model.id_credit_restriction"></Creditrestriction>
                                        </v-col>
                                        <v-col>
                                            <NumberField dusk='discount'  :disabled="model.isStatusAtLeastAccepted" amount :model="model" v-model="model.discount" :currency="model.crnc_code"></NumberField>
                                        </v-col>
                                    </v-row>
                                </FormRow>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="model.isTypeInvoice">
                            <v-col class="pr-xl-4">
                                <FormRow col-label="4" label="Dispuut">    
                                    <DisputeCombo dusk='id_dispute' :model="model" :rules="model.rules.id_dispute" v-model="model.id_dispute"></DisputeCombo>
                                </FormRow>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="pl-xl-4">
                        <v-row dense  v-if="model.isTypeInvoice">
                            <v-col class="">
                                <FormRow col-label="4" label="Factuurnummer">            
                                    <TextField dusk='invoicenr' :disabled="model.isStatusAtLeastAccepted" max-length="45" :model="model" :rules="model.rules.invoicenr" placeholder="Factuurnr" v-model="model.invoicenr"></TextField>
                                </FormRow>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="">
                                <FormRow col-label="4" label="Factuurdatum">
                                    <v-row dense>
                                        <v-col sm="6">            
                                            <DatePicker dusk='invoicedate'  :disabled="model.isStatusAtLeastAccepted" :model="model" :rules="model.rules.invoicedate" v-model="model.invoicedate"></DatePicker>
                                        </v-col>
                                    </v-row>
                                </FormRow>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="settings.use_bookdate" >
                            <v-col class="">
                                <FormRow col-label="4" label="Boekdatum">            
                                    <v-row dense>
                                        <v-col sm="6">            
                                            <DatePicker dusk='bookdate' :disabled="model.isStatusAtLeastAccepted" :model="model" :rules="model.rules.bookdate" v-model="model.bookdate"></DatePicker>
                                        </v-col>
                                    </v-row>
                                </FormRow>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="model.isTypeInvoice">
                            <v-col class="">
                                <FormRow col-label="4" label="Betaaltermijn">            
                                    <v-row dense>
                                        <v-col sm="6">            
                                            <NumberField dusk='paymentdays' :disabled="model.isStatusAtLeastAccepted" :model="model" min="0" max="100" suffix="dagen" integer :rules="model.rules.paymentdays"  v-model="model.paymentdays"></NumberField>
                                        </v-col>
                                    </v-row>
                                </FormRow>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="model.isTypeInvoice" >
                            <v-col class="">
                                <FormRow col-label="4" label="Opmerking">            
                                    <TextField dusk='note' :disabled="model.isStatusAtLeastAccepted" placeholder="Opmerking (optioneel)" max-length="40" :model="model" :rules="model.rules.note" v-model="model.note"></TextField>
                                </FormRow>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
</template>



<script setup>
    
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Checkbox from "@controls/check/Checkbox.vue";
    import ToggleButtonGroup from '@controls/buttons/ToggleButtonGroup'
    import RelationCombo from '@controls/combo/Relation'
    import NumberField from '@controls/input/NumberField'
    import DatePicker from '@controls/input/DatePicker'
    import Creditrestriction from '@controls/combo/Creditrestriction'
    import RelationIBAN from '@/ui/controls/RelationIBAN'
    import {purchaseinvoice as settings} from '@app/settings'
    import {computed} from 'vue'    
    import {relation} from '@app/list';
    import CurrencyCombo from '@controls/combo/Currency'
    import DisputeCombo from '@controls/combo/Dispute'
    import feature from "@app/features"


    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    /**
     * Show the g account when the relation has a g account.
     */
    const showGAccount = computed({
        get() {
            if (!props.model.id_relation) {
                return false;
            }

            let rel = relation.one(props.model.id_relation);
            if (!rel) {
                return false;
            }            
            if (props.model.ibang) {
                return true;
            }
            return !!(rel.bankaccounts||[]).find( (x) => x.is_g_account && !x.archived_at);
        }
    })
</script>

<template>

    <Picker :useMenu="!!valueModel[idField]"
        :disabled="disabled"
        :skeleton="skeleton"
        :rules="rules"
        :noClearable="noClearable"
        :placeholder="placeholder"
        :model="model"         
        :valueModel="valueModel"
        :filter="filter"
        :idField="idField" 
        :repField="repField"
        :selectIdField="selectIdField" 
        :selectRepField="selectRepField" 
        :fieldMap="fieldMap"
        dialogName="select_product"
        :noBorder="noBorder"
        :propagateClear="propagateClear"
        @select="onSelect"
    >
    <template v-slot:menu="{id,clear,disabled, search}">
        <ActionMenuItem :disabled="disabled" v-if="id&&compIsRecept" icon="search" @click="()=>search()">Zoek ander recept</ActionMenuItem>
        <ActionMenuItem :disabled="disabled" v-if="id&&!compIsRecept" icon="search" @click="()=>search()">Zoek ander artikel</ActionMenuItem>
        <ActionMenuItem :disabled="disabled" v-if="compIsRecept" icon="expand" @click="()=>onExpand(id)">Klap recept uit</ActionMenuItem>
        <ActionMenuItem action="recipe.open" v-if="id&&compIsRecept" icon="open" @click="()=>openItem(id)">Open recept</ActionMenuItem>
        <ActionMenuItem action="product.open" v-if="id&&!compIsRecept" icon="open" @click="()=>openItem(id)">Open artikel</ActionMenuItem>
        <ActionMenuItem :disabled="disabled" icon="remove" @click="()=>clear()">Maak leeg</ActionMenuItem>

        <v-divider></v-divider>
        <NamedRouteMenuItem route="products" icon="arrowright" class="text-primary">
            Sluit venster en ga naar Artikelen
        </NamedRouteMenuItem> 

    </template>

    </Picker>

</template>

<script setup>
    // import ProductPicker from '@controls/picker/Product'
    // <ProductPicker :model="model" :rules="model.rules.id_product" v-model="model.id_product"></ProductPicker>

    import Icon from '@controls/icons/Icon'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";        
    import {computed, ref} from 'vue'
    import string from '@lib/string'
    import dlg from '@app/dlg'
    import Picker from '@controls/picker/Picker'
    import NamedRouteMenuItem from "@controls/menu/NamedRouteMenuItem.vue";

    const props = defineProps({

        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        noBorder: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        placeholder: {
            type: [String],
            default: 'Artikel'
        },
        model: {
            type: [Object]
        },
        valueModel: {
            type: [Object]
        },
        // Mandatory. The name of the field in the valueModel or model which is the id of this picker field
        idField: {
            type: [String],
            default: 'id_product'            
        },
        // Mandatory. The name of the field in the valueModel or model which is the representation of this picker field
        repField: {
            type: [String],
            default: 'pd_code'            
        },
        // Optional. The id in the select list which we take over on select
        selectIdField: {
            type: [String],
            default: "id"
        },
        // Optional. The repField in the select list which we take over on select. Defaults to the property 'repField'
        selectRepField: {
            type: [String],
            default: 'pd_code'            
        },
        // When provided, the fieldmap contains fields which must be taken over from a selected item.
        // Those are sibling fields. For example, for a project picker, the reference may be displayed in a separate field or column.
        fieldMap: {
            type: [Object]
        },
        // When the field is filled, and then cleared, should the new values (empty value) be propagated to
        // any depending field? (defined in the fieldmap)
        propagateClear: {                    
            type: [Boolean]
        },
        // The default filter used for browsing. 
        // Example, for selecting a project in a purchase invoice, only projects which are in phase where it is allowed to 
        // book purchase invoices should be found. The same for sales invoices. 
        filter: {
            type: [Object]
        },
        // When the product is opened from the picker, should it be opened readonly? 
        openReadonly:  {                    
            type: [Boolean],
            default: false
        },
    })
    
    const compIsRecept = computed({
        get() {
            var rep = props.valueModel[props.repField] || "";
            return string.startsWith(rep, "R0");
        }
    })

    const emit = defineEmits(['expand', 'select', 'updated'])
    function onExpand(id) {
        emit('expand', {id: id})
    }
    function onSelect(v) { 
        emit('select', v); 
    }
    

    // Open either the recipe or the product. In the archive overview, both are displayed.
    async function openItem(id) {
        var dlgType = compIsRecept.value ? "recipe" : "product";
        var result = await dlg.promised.tryOpen(dlgType, {id: id}, {readonly: props.openReadonly} );
        if (result) {
            emit('updated', result); 
        }
    }


</script>

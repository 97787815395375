import { clsModel, fnCreate } from '@cls/clsModel'
import { purchaseinvoices as api } from '@/app/api'
import Constants from '@app/consts'
import bool from '@lib/bool'
import string from '@lib/string'
import {numeric} from '@lib/numeric'
import {relation, creditrestriction} from '@app/list';
import vat from '@lib/vat'
import {vat as vatlist} from '@app/list';
import eventbus from '@app/eventbus'

const mandatoryFields = ['invoicenr', 'invoicedate', 'id_relation'];
          
var modelName = "purchaseinvoice";
const id_optimit_type = Constants.optimit_types.purchaseinvoice;
const fields = [
    'id'                    , 
    'type'                  ,
    'total_payable'         ,
    'invoicenr'             ,
    'invoicedate'           ,
    'bookdate'              ,
    'crnc_code'             ,
    'note'                  ,
    'id_relation'           ,
    'id_dispute'            ,
    'incasso'               ,
    'is_paid'               ,
    'paymentdays'           ,
    'iban'                  ,
    'ibang'                 ,
    'g_amount'              ,
    'id_credit_restriction' ,
    'discount'              ,
    'pi_status'             , // display only
    'ubl_status'            ,
    'ubl_status_message'    ,
    'vatlines'              ,
    'detaillines'           ,
];



class clsVatLine {
    id_ledger = null;
    _id_vat = null; 
    _amount_vat = null;
    _amount_excl = null;

    constructor(data) {
        data = data || {};
        this._id_vat = data.id_vat || Constants.defaults.id_vat;
        this.id_ledger = data.id_ledger;
        this._amount_vat = data.amount_vat || 0;
        this._amount_excl = data.amount_excl || 0;
    }   

    get amount_vat() { return this._amount_vat;}
    set amount_vat(value) { 
        this._amount_vat = value;
        // Only when we can calculate the excl. amount from the vat, do it.
        if (!vat.is0pct(this.id_vat)) {
            this._amount_excl = vat.vat2excl(this.id_vat, this._amount_vat);
        }
    }
    get amount_excl() { return this._amount_excl;}
    set amount_excl(value) { 
        this._amount_excl = value;
        this._amount_vat = vat.excl2vat(this.id_vat, this._amount_excl);
    }
    get id_vat() { return this._id_vat;}
    set id_vat(value) {         
        // When the vat changes, the line totals need to be recalculated.
        // That depends on the other lines which are available.
        // Therefore, the calculation is defered to the parent invoice via an event.
        // See TabPurchaseInvoiceVatLines.vue
        // --> clear the         
        this._id_vat = value;
//        if (value == vat.ID_VAT_SHIFTED || value == vat.ID_VAT_FOREIGN) {
//            this._amount_vat = 0;
//        }
    }
    calculateVat() {
        this._amount_vat = vat.excl2vat(this.id_vat, this._amount_excl);
    }


    /**
     * From the given total, calculate the excl. vat and the vat components.
     * @param {} total 
     */
    fromTotal(total) {
        this._amount_excl = vat.incl2excl(this.id_vat, total);
        this._amount_vat  = vat.incl2vat(this.id_vat, total);        
    }

    toJSON() {
        return {
            id_ledger: this.id_ledger,
            id_vat: this._id_vat,
            amount_vat: this._amount_vat,
            amount_excl: this._amount_excl,       
        }
    }

}

class clsVatLines {
    _lines = [];

    get lines() {
        return this._lines;
    }

    set lines(arr) {
        let vatlines = []; 
        (arr||[]).forEach( (line) => {
            vatlines.push(new clsVatLine(line));
        })
        this._lines = vatlines;
    }
    getVatTypeForNewLine() {
        if (this.type == 'shifted') {
            return vat.ID_VAT_SHIFTED;
        }
        else if (this.type == 'foreign') {
            return vat.ID_VAT_FOREIGN;
        }
        return vat.ID_VAT_HIGH
    }
    addLine(line) {
        line = line || {
            id_vat: this.getVatTypeForNewLine()
        };
        this._lines.push(new clsVatLine(line));
    }
    removeLine(line) {
        this._lines = this._lines.filter( (l) => l != line);
    }

    get total_vat() {
        let total = 0;
        (this._lines||[]).forEach( (line) => {
            total += line.amount_vat;
        })
        return total;
    }
    
    get total_excl() {
        let total = 0;
        (this._lines||[]).forEach( (line) => {
            total += line.amount_excl;
        })
        return total;
    }

    /**
     * Depending on the available lines, retrieve the overall VAT type.
     * - when one or more lines has vat shifted --> SHIFTED 
     * - when one or more lines has vat foreign --> FOREIGN
     * - otherwise: DEFAULT 
     */
    get type() {
        for (var n = 0; n < this.lines.length; n++) {
            let line = this.lines[n];
            if (vat.isShifted(line.id_vat)) {
                return "shifted";
            }
            if (vat.isForeign(line.id_vat)) {
                return "foreign";
            }
        }
        return "standard";
    }
    /**
     * When the overall vat type changes, change all lines.
     */
    set type(value) {
        let id_vat = vat.ID_VAT_HIGH;
        if (value == 'shifted') {    
            id_vat = vat.ID_VAT_SHIFTED;
        } else if (value == 'foreign') {
            id_vat = vat.ID_VAT_FOREIGN;
        } 

        (this._lines||[]).forEach( (line) => {
            line.id_vat = id_vat;
            line.calculateVat();
        })
    }

    toJSON() {
        let lines = [];
        this._lines.forEach( (line) => {
            lines.push(line.toJSON())
        })
        return lines;
    }

}

////////////////////////////////////////////////////////////////////////////////
//
// 
//
////////////////////////////////////////////////////////////////////////////////
class clsDetailLine {
    
    _number = null;
    id_unit = null;
    description = null;
    id_product = null;
    pd_code = null;
    pd_name = null;
    pd_supplier_code = null;
    sup_name = null;
    id_project = null;
    pro_name = null;
    pro_reference = null;
    pro_number = null;
    id_cost_type = null;
    amount = null;
    id_project_chapter = null;
    ch_rep = null;

    get total() { return this.number * Number(this.amount);}
    get number() { return this._number || 1;}
    set number(value) {  this._number = value || 1; }

    constructor(data) {
        data = data || {}
        this.id_unit = data.id_unit;
        this.description = data.description;
        this.id_product = data.id_product;
        this.pd_code = data.pd_code;
        this.pd_name = data.pd_name;
        this.pd_supplier_code = data.pd_supplier_code;
        
        this.sup_name = data.sup_name;
        this.id_project = data.id_project;
        this.pro_name = data.pro_name;
        this.pro_number = data.pro_number;
        this.pro_reference = data.pro_reference;
        this.id_project_chapter = data.id_project_chapter || null;
        this.ch_rep = data.ch_rep || null;    
        this.id_cost_type = data.id_cost_type;
        this.amount = data.amount || 0;
        this.number = data.number;
    }   

    toJSON() {
        return {
            id_unit: this.id_unit,
            description: this.description,
            id_product: this.id_product,
            id_project: this.id_project,
            id_cost_type: this.id_cost_type,
            amount: this.amount,
            number: this.number,
            total: this.total,
            id_project_chapter: this.id_project_chapter,        
        }
    }

}

class clsDetailLines {
    _lines = [];

    get lines() {
        return this._lines;
    }

    set lines(arr) {
        let detaillines = []; 
        (arr||[]).forEach( (line) => {
            detaillines.push(new clsDetailLine(line));
        })
        this._lines = detaillines;
    }

    addLine(line) {
        line = line || {
        };
        this._lines.push(new clsDetailLine(line));
    }

    removeLine(line) {
        this._lines = this._lines.filter( (l) => l != line);
    }

    // Get the last added line.
    get lastLine() {
        if (!this._lines || !this._lines.length) {
            return  null;
        }
        return this._lines[this._lines.length-1];
    }

    get total() {
        let total = 0;
        (this._lines||[]).forEach( (line) => {
            total += line.total;
        })
        return total;
    }
    
    toJSON() {
        let lines = [];
        this._lines.forEach( (line) => {
            lines.push(line.toJSON())
        })
        return lines;
    }

}

////////////////////////////////////////////////////////////////////////////////
//
//
class clsPurchaseInvoice extends clsModel {

    // When the dispute status changes after save, some statistics might need updates.
    originalDispute       = false;

    id                    = null;
    type                  = null;
    invoicenr             = null;
    _invoicedate           = null;
    bookdate              = null;
    _crnc_code             = null;
    note                  = null;    
    is_paid               = null;
    paymentdays           = null;
    iban                  = null;
    ibang                 = null;
    g_amount              = null;
    discount              = null;
    id_dispute            = null;
    _id_relation          = null;
    _incasso               = null;
    _id_credit_restriction = null;
    _total_payable         = null;    
    ubl_status             = null;
    ubl_status_message     = null;

    pdf = null;
    // Get the pdf data. 

    // Note that the data is base64 encoded.
    pdfData() {
        return this.pdf;
    }

    _vatlines               = new clsVatLines();
    get vatlines() { return this._vatlines; }
    set vatlines(value) { this._vatlines.lines = value; }

    _detaillines               = new clsDetailLines();
    get detaillines() { return this._detaillines; }
    set detaillines(value) { this._detaillines.lines = value; }
        
    get modelRep() {
        return this.invoicenr;
    }
    get statusRep() {
        switch (string.lower(this.pi_status)) {
            case "new"        : return "Nieuw";
            case "accepted"   : return "Goedgekeurd";
            case "processed"  : return "In boekhouding";
            case "rejected"   : return "Afgekeurd";
            case "enrichment" : return "Wordt verwerkt";
        }
        return "-";
    }
    /**
     * Is the status in at least 'accepted'?
     * That is, accepted and / or sent to accountancy
     */
    get isStatusAtLeastAccepted() {
        switch (string.lower(this.pi_status)) {
            case "accepted"   : 
            case "processed"  : return true;
        }
        return false;
    }
    /**
     * Is the status accepted --> AND NOT SENT TO ACCOUNTANCY?
     */
    get isStatusAccepted() {
        switch (string.lower(this.pi_status)) {
            case "accepted"   :  return true;
        }
        return false;
    }
    /**
     * Is the status accepted --> AND NOT SENT TO ACCOUNTANCY?
     */
    get isStatusRejected() {
        switch (string.lower(this.pi_status)) {
            case "rejected"   :  return true;
        }
        return false;
    }
    /**
     * Is the invoice sent to accountancy?
     */
    get isStatusSentToAccountancy() {
        switch (string.lower(this.pi_status)) {
            case "processed"  : return true;
        }
        return false;
    }

    /**
     * The invoice is currently being converted to UBL.
     */
    get isUBLStatusConverting() {
        return this.ubl_status == "converting";
    }

    /**
     * The invoice is currently being converted to UBL.
     */
    get isUBLStatusError() {
        return this.ubl_status == "error";
    }
    // Clear the ubl status. This might trigger an action like 'enable data typing'. 
    resetUblStatus() {
        this.ubl_status = null;
    }
    // Show a message when data is either being converted to UBL or when an error
    // was encountered in the process of converting. 
    get showStatusMessage() {
        return this.isUBLStatusConverting || this.isUBLStatusError;
    }
    // In addition to the status message:
    // When the data is being converted from UBL, the user should not fill the data himself. 
    // This is because it is a waste of time. So, show a message and only when the user wants so, 
    // the data should be displayed.
    get showInputData() {
        return !this.showStatusMessage;
    }
    get disabled() {
        return super.disabled || this.showStatusMessage;
    }

    /**
     * Is the purchase invoice of type 'invoice', as opposed to 'pin' or 'cash'
     */
    get isTypeInvoice() {
        return string.compare(this.type, 'invoice');
    }

    /**
     *  get/set the incasso flag and propagate set to the is_paid field
     */
    get incasso() { return this.bool(this._incasso); }
    set incasso(value) {
        this._incasso = this.bool(value);
        if (this.isFilling) {
            return;
        }

        if (this._incasso) {
            this.is_paid = true;
        }
    }
    // The currency code. 
    // When manually set to anything but 'EUR', set the type foreign.
    get crnc_code() {
        return this._crnc_code;
    }
    set crnc_code(v) {
        this._crnc_code = v;
        if (this.isFilling) {
            return;
        }
        if (!!v && v != 'EUR') {
            this.vatlines.type = 'foreign';
        }
    }

    /**
     *  get/set the incasso flag and propagate set to the is_paid field
     */
    get invoicedate() { return this._invoicedate; }
    set invoicedate(value) {
        this._invoicedate = value;
        if (this.isFilling) {
            return;
        }

        if (this._invoicedate) {
            this.bookdate = this._invoicedate;
        }
    }

    /**
     * From a selected relation, set the iban / g account when there is only one.
     */
    setRelationIBAN() {
        this.iban = null;
        this.ibang = null;

        let rel = relation.one(this.id_relation);
        if (!rel) {
            return;
        }            
        let accounts = rel.bankaccounts.filter( (ba) => !ba.is_g_account && !ba.archived_at);
        let gaccounts = rel.bankaccounts.filter( (ba) => ba.is_g_account && !ba.archived_at);
        if (accounts && accounts.length === 1) {
            this.iban = accounts[0].iban;
        }
        if (gaccounts && gaccounts.length === 1) {
            this.ibang = gaccounts[0].iban;
        }
    }

    /**
     *  get/set the relation field and propagate defaults for the relation to appropriate fields.
     */
    get id_relation() { return this._id_relation; }
    set id_relation(value) {        
        this._id_relation = value;
        if (this.isFilling) {
            return;
        }
        let rel = relation.one(this.id_relation);
        if (!rel) {
            this.id_credit_restriction = null;    
            return;
        }         

        if (!!rel.rel_credit_incasso) {
            this.incasso = 1;
        }

        this.paymentdays = rel.rel_credit_payment_terms_days || 30;
        this.id_credit_restriction = rel.id_credit_restriction;
        if (this.id_credit_restriction) {
            this.applyCreditRestriction(); // calculate the discount
        }
        this.setRelationIBAN(); // TEMPORARY SOLUTION --> UNDER discussion.
    }
    get rel_name() {
        let rel = relation.one(this.id_relation);
        if (!rel) {
            return '-';            
        }         
        return rel.rel_name;
    }
    /**
     * get / set the credit restriction and calculate the discount
     */
    get id_credit_restriction() { return this._id_credit_restriction; }
    set id_credit_restriction(value) {
        this._id_credit_restriction = value;
        if (this.isFilling) {
            return;
        }
        this.applyCreditRestriction();
    }

    get missing_line_amount() {
        let total = this.vatlines.total_vat + this.vatlines.total_excl;
        return numeric.round(this.total_payable - total, 2); 
    }

    /**
     * When an amount is not calculated, compensate the missing amount in the current line.
     *  
     * @param {*} line 
     * @returns 
     */
    calcDetailLineAmount(line) {
        if (!this.missing_detail_amount) {
            return;
        }
        let lineTotal = line.total;
        let totalFillable = lineTotal + this.missing_detail_amount;
        let lineNumber = line.number;
        let itemPrice = totalFillable/lineNumber;
        line.amount = itemPrice;
    }

    /**
     * The amount which is not in the detail lines yet
     * Note that this is compared to the excl VAT amount. 
     */
    get missing_detail_amount() {
        let total = this.detaillines.total;
        return numeric.round(this.vatlines.total_excl - total, 2); 
    }

    /**
     * get / set the total payable amount and calculate the new discount in case credit restriction is set.  
     */
    get total_payable() { return this._total_payable; }
    set total_payable(value) {
        this._total_payable = value;
        if (this.isFilling) {
            return;
        }
        this.applyCreditRestriction(); // calculate the discount when applicable
        this.applyVatLineAmounts();    // If possible, set the vat amounts matching the grand total
    }

    /**
     * Download the document.
     * @returns 
     */
    async downloadData() {
        if (!this.id) {
            return null;
        }
        return api.downloadData(`download/${this.id}`, true);  
    }
    /**
     * When possible, apply the total payable amount to the vat lines.
     * --> if there is just one vat line, calculate the excl amount and the vat.
     * 
     */
    applyVatLineAmounts() {
        if (this.vatlines.lines.length == 1) {
            this.vatlines.lines[0].fromTotal(this.total_payable);
        }
    }

    /**
     * For the given line, calculate the values so that the total line amount matches the invoice amount. 
     * Scenario: 
     *  - invoice has 2 lines, both 21%. Now the 2nd is changed to 9%. 
     *    After this operation, the 9% line should be recalculated so that no missing amount is left. 
     * 
     * Note: when no line is provided, this method will: 
     *    - when there is only one line: use that one
     *    - otherwise, do nothing. 
     * 
     * @param {*} line 
     */
    calcVatLine(line) {
        line._amount_vat = 0;
        line._amount_excl = 0;
//        let targetAmount = this.missing_line_amount + line.amount_vat + line.amount_excl;
        let targetAmount = this.missing_line_amount;

        line._amount_vat = vat.incl2vat(line.id_vat, targetAmount)
        line._amount_excl = vat.incl2excl(line.id_vat, targetAmount)
    }

    /**
     * *******************************************************************************************
     * Note about timing.
     *  
     * Credit restriction is applied over either the incl vat amount (credit restriction)
     * or over the amount ex vat (payment discount).
     * Now, the point is that the ex amount is calculated over a calculated amount.
     * 
     * This means, we have a timing issue. 
     * Is this method called first, or is the calculation of the excl. vat total executed first?
     * We must assume that this is called first, which will ruin the calculation.
     * Therefore we must anticipate and we do this by using a timeout. 
     * This makes sure we are out of the current processing loop.
     * *******************************************************************************************
     * 
     * 
     * Apply the credit restriction to the invoice. 
     * Kredietbeperking:
     *   - Calculate discount over total payable - total incl VAT
     * Betalingskorting: 
     *   - Calculate discount over total line amount - total excl VAT
     * 
     * The credit restriction should be applied when: 
     *   - the total payable is changed
     *   - a line amount is changed 
     *   - the credit restriction is changed 
     * 
     * @returns 
     */
    applyCreditRestriction() {
        let cr = creditrestriction.one(this._id_credit_restriction);
        if (!cr) {
            this._id_credit_restriction = null;    
            return;
        }
        var pct = Number(cr.cr_percentage);

        // By default, assume credit restriction - the discount is over the total incl VAT.         
        let amount = this.total_payable;

        // No Credit restriction, so, payment discount, over amount excl vat        
        if (!string.compare(cr.cr_type, 'C')) {
            // See explanation above. The 100ms is more or less arbitrary. Most imporant is, we are out of the update loop.
            setTimeout( ()=> {
                var amount = this.vatlines.total_excl;
                this.discount = numeric.round((pct/100) * amount, 2);    
            }, 100)
            return;
        }

        this.discount = numeric.round((pct/100) * amount, 2);
    }

    
    /**
     * When the dispute flag is cleared or set, statistics might need updates.
     */
    onAfterSave(resultData) {
        var id_dispute = this.id_dispute;
        if ( (!id_dispute && this.originalDispute)
           || (id_dispute && !this.originalDispute)) {
            this.sendStatsRefreshEvent();   
        }
    }

    async checkBeforeSave(data) {
        await api.getWarnings(data);     
    }
    async accept() {
        try {
            this.isLoading = true;
            let result = await api.accept(this.id);     
            this.sendSavedEvent(result.data)
            this.sendStatsRefreshEvent()
            this.fill(result.data);     
        }
        finally {
            this.isLoading = false;
        }
    }
    async reject(reason) {
        try {
            this.isLoading = true;
            let result = await api.reject(this.id, reason);     
            this.sendSavedEvent(result.data)
            this.sendStatsRefreshEvent()
            this.fill(result.data);     
        }
        finally {
            this.isLoading = false;
        }
    }
    async setStatusNew() {
        try {
            this.isLoading = true;
            let result = await api.setStatusNew(this.id);     
            this.sendSavedEvent(result.data)
            this.sendStatsRefreshEvent()
            this.fill(result.data);     
        }
        finally {
            this.isLoading = false;
        }
    }


    /**
     * Add a line to the vat list
     * @param {*} line 
     */
    addVatLine() {
        let line = {
            id_vat: this.vatlines.getVatTypeForNewLine(),
            amount_vat: 0, 
            amount_excl: 0, 
        }
        let missing = this.missing_line_amount;
        if (missing) {
            line.amount_vat = vat.incl2vat(line.id_vat, missing);
            line.amount_excl = vat.incl2excl(line.id_vat, missing);
        }
        this.vatlines.addLine(line);
    }
    /**
     * Remove a vat line
     * @param {} line 
     */
    removeVatLine(line) {
        this.vatlines.removeLine(line);
    }
    /**
     * Add a line to the vat list
     * @param {*} line 
     */
    addDetailLine(line) {
        line = line || {}
        if (!line.amount) {
            let missing = this.missing_detail_amount;
            if (missing) {
                line.amount = missing;
            }
        }

        this.detaillines.addLine(line);
    }
    /**
     * Remove a detail line
     * @param {} line 
     */
    removeDetailLine(line) {
        this.detaillines.removeLine(line);
    }
    /**
     * Add a product line to the list.
     * @param {} product 
     * @returns 
     */
    addDetailProductLine(product) {
        if (!product) {
            return;
        }
        let line = {
            id_product: product.id,
            pd_code: product.pd_code,
            pd_name: product.pd_name,
            sup_name: product.sup_name,
            amount: product.pd_purchase_price,
            description: string.left(product.pd_name,45),
            id_unit: product.id_unity,
        }
        // If lines are already present, and a project is used, copy it.
        if (this.detaillines.lastLine && this.detaillines.lastLine.id_project) {
            line.id_project = this.detaillines.lastLine.id_project;
            line.pro_name   = this.detaillines.lastLine.pro_name;
            line.pro_number   = this.detaillines.lastLine.pro_number;
            line.pro_reference   = this.detaillines.lastLine.pro_reference;
            line.id_project_chapter = this.detaillines.lastLine.id_project_chapter;
            line.ch_rep = this.detaillines.lastLine.ch_rep;
        } 
        if (product.amount) {
            line.number = product.amount;
        }
        return this.addDetailLine(line);
    }


    /**
     * Make sure that the vendors array is always present, frontend probably depends on array type. 
     * @param {} data 
     * @returns 
     */
    fill(data) {
        
        data = data ||{};
        data.type                  = data.type                  || 'invoice';
        data.total_payable         = data.total_payable         || 0;
        data.crnc_code             = data.crnc_code             || 'EUR';
        data.paymentdays           = data.paymentdays           || 30;
        data.g_amount              = data.g_amount              || 0;
        data.id_credit_restriction = data.id_credit_restriction || 0;
        data.incasso               = bool.isTrue(data.incasso);
        data.is_paid               = bool.isTrue(data.is_paid);
        data.discount              = data.discount              || 0;
        if (!data.vatlines || !data.vatlines.length) {
            data.vatlines = [{
                id_vat: vat.ID_VAT_HIGH, 
                amount_vat: 0, 
                amount_excl: 0, 
            }]
        }
        if (!data.detaillines || !data.detaillines.length) {
            data.detaillines = []
        }
        this.pdf = data.pdf;

        this.originalDispute = data.id_dispute; 
        return super.fill(data);
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: mandatoryFields,
          id_optimit_type: id_optimit_type, 
          fillable: fields
        })
    } 

 }
 export default fnCreate(clsPurchaseInvoice , 'clsPurchaseInvoice');

//
// Usage: 
//  import user from '@app/user'
//

import clsCurrentuser from '@/app/clsCurrentuser'
import {ref} from 'vue'

let obj = ref(new clsCurrentuser());

export default obj.value;

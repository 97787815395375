<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <Row :l="colL" label="Dag">
                <span class="w-3 d-inline-block">     <ToggleButton :model="model" v-model="model.mon"><span class="text-bold">Ma</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.fmt_date_mon }}</span></ToggleButton></span>
                <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.tue"><span class="text-bold">Di</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.fmt_date_tue }}</span></ToggleButton></span>
                <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.wed"><span class="text-bold">Wo</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.fmt_date_wed }}</span></ToggleButton></span>
                <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.thu"><span class="text-bold">Do</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.fmt_date_thu }}</span></ToggleButton></span>
                <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.fri"><span class="text-bold">Vr</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.fmt_date_fri }}</span></ToggleButton></span>
                <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.sat"><span class="text-bold">Za</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.fmt_date_sat }}</span></ToggleButton></span>
                <span class="ml-1 w-3 d-inline-block"><ToggleButton :model="model" v-model="model.sun"><span class="text-bold">Zo</span><span style="margin-top:2px;" class="v-center text-extra-small ml-1">{{ model.fmt_date_sun }}</span></ToggleButton></span>
            </Row>
            <Row :l="colL">
                <Checkbox switcher :model="model" v-model="model.specify_start_end_time" label="Specificeer start- end eindtijd"></Checkbox>
            </Row>
            <Row :l="colL" :r="colR" v-if="model.specify_start_end_time" label="Start- en eind-tijd">
                <Row even >
                    <template v-slot:left>
                        <TimeField :slim-fit="false" hm :model="model" v-model="model.hr_start_time"></TimeField>
                    </template>
                    <TimeField :slim-fit="false" hm :model="model" v-model="model.hr_end_time"></TimeField>
                </Row>
            </Row>
            <Row :l="colL" :r="colR" label="Gewerkte uren">
                <Row even >
                    <template v-slot:left>
                        <TimeField :slim-fit="false" :model="model" v-model="model.hr_total_minutes"></TimeField>
                    </template>
                </Row>
            </Row>
            <Row :l="colL" :r="colR" label="Tarief">
                <TariffCombo :rules="model.specify_start_end_time?[]:model.rules.mandatory" :model="model" v-model="model.id_tariff"></TariffCombo>
            </Row>
            <Row :l="colL" :r="colR" label="Urentype">
                <HourTypeCombo :model="model" :rules="model.rules.mandatory" v-model="model.id_hour_type"></HourTypeCombo>                            
            </Row>
            <Row v-if="feature.canProject"   :l="colL" :r="colR" label="Project">
                <ProjectPicker :disabled="!model.isForProject" :rules="model.isForProject?model.rules.mandatory:[]" :filter="{'selectfor':'hours'}" :fieldMap="projectFieldMap" :model='model' :valueModel="model.cline" v-model='model.id_project'></ProjectPicker>
            </Row>
            <Row :l="colL" :r="colR" label="Opmerking">
                <TextField max-length="255" placeholder="Opmerking" :model="model" v-model="model.hr_remark"></TextField>
            </Row>
        </v-form>
    </Dialog>
</template>



<script setup>
    // column widths
    const colL={sm:4,lg:2}
    const colR={sm:4,lg:4}    
    
    import useModel from '@/models/hours/hourline'
    import Dialog from '@shared/ui/dialogs/ActionDialog'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import ProjectPicker from '@controls/picker/Project'
    import feature from '@app/features'
    import Checkbox from '@controls/check/Checkbox.vue'
    import HourTypeCombo from '@controls/combo/HourType'
    import TariffCombo from '@controls/combo/Tariff'
    import TimeField from '@controls/input/TimeField'
    import ToggleButton from '@controls/buttons/ToggleButton'
    import AlertArea from '@shared/ui/markup/AlertArea'
    import Row from '@controls/forms/Row'

    // Our fields which come from projects. We want them overridden when a project is selected.
    let projectFieldMap = {pro_reference: 'ord_reference', pro_name: 'ord_name', pro_number: 'pro_number'};

    const form = ref();


    let model = useModel();
    let dlg = ref(
        new clsDialog("hourline", "Urenregistratie", model, [form], {
            maxWidth:1100,
            icon: 'hours'
        })).value;


</script>

<template>    
    <v-skeleton-loader type="opti-input" v-if="compSkeleton"></v-skeleton-loader>
    
    <v-menu v-else
        v-model="open"
        :close-on-content-click="false"        
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
    <template v-slot:activator="{ on }">
        <v-text-field
            class="input-datefield xtext-right"
            :class="compClass"
            @keydown="keyDown"
            @blur="onBlur"
            hide-details 
            :disabled="compDisabled"
            dense 
            outlined
            :rules="rules"
            :placeholder="compPlaceholder"
            :label="label"
            ref="textfield"
            nov-model="dvalue"
            :value="dvalue"
            >
            <template v-slot:append>            
                <span class="ml-2 cursor-pointer" 
                    @click="if(!compDisabled)open=!open"> 
                    <Icon class="disabled mt-1" small type="calendar"></Icon>
                </span>
            </template>
        </v-text-field>
    </template>
    <v-date-picker first-day-of-week="1" no-title show-week v-model="pickerValue" @input="setPicker" atinput="open = false">
        <v-btn class="text-center" @click="onToday" text small color="primary">Vandaag</v-btn>                
        <v-spacer></v-spacer>         
        <v-btn class="text-center" @click="onClear" text small color="primary">Maak veld leeg</v-btn>                
    </v-date-picker>
    </v-menu>
</template>

<script setup>

import {date, time} from '@lib/date'
import {computed, ref} from 'vue'
import string from '@lib/string'
import Icon from '@controls/icons/Icon'

//
// Usage:
// import DatePicker from '@controls/input/DatePicker'
// <DatePicker :model="model" v-model="model.inv_date"></DatePicker>
//
//
// Implementation Note
//   We do not connect the v-model of the text field directly to the model value.
//   That is because here (in the control), we fix invalid input. 
//   Imaging what would happen if we were connected to the model value directly: 
//    - Somebody types an invalid value:  00:88. 
//    - on Blur, the control fixes this and the corrected value (00:59) is passed back
//      to the model in its minutes value (59). 
//    - All fine until now. 
//    - Now the user types 00:88 again. 
//    - The control fixes up again and passes 59 to the model. 
//    - Now we have a problem. 
//      The model value was 59 already, so it does not change. 
//      Because it does not change, the control is not updated, and just displays 00:88.
//   
//   This is the easiest explainable scenario where things go wrong. 
//   For this reason, we use an internal dummy (dvalue), which we use as direct control binding, and 
//   in parallel, we pass corrected values back to the model.  
//

const props = defineProps({
    value: {
        type: [String, Number]
    },
    disabled: {
        type: [Boolean]
    },
    label: {
        type: String
    },
    placeholder: {
        type: [String],
        default: 'DD-MM-JJJJ'
    },
    rules: {
        type: [Array]
    },
    noBorder: {
        type: [Boolean]
    },
    borderHover: {
        type: [Boolean]
    },
    slimFit: {
        type: [Boolean],
        default: true
    },            
    block: {
        type: [Boolean],
        default: false
    },            
    skeleton: false,
    model: {
        type: [Object]
    },
    offsetSource: {
        type: [Date, String]
    },
    textError: {
        type: [Boolean],
        default: false
    },            
})

const compClass = computed({
    get() {
        var border = props.noBorder ? "no-border" : ""; 
        var slimfit = ((!props.block) && props.slimFit) ? "slim-fit" : ""; 
        var borderHover = props.borderHover ? "border-hover" : ""; 
        var textError = props.textError ? "text-error" : "";
        return `${border} ${borderHover} ${slimfit} ${textError}`;
    }
})
const compSkeleton = computed({
    get() {
        if (props.skeleton) {
            return true;
        }
        if (!props.model) {
            return false;
        }
        return props.model.isDataLoading;
    }
})
const compDisabled = computed({
    get() {
        if (props.disabled) {
            return true;
        }
        if (!props.model) {
            return false;
        }
        return props.model.disabled;
    }
})
const compPlaceholder = computed({
    get() {
        if (compDisabled.value) {
            return null;
        }
        if (props.label) {
            return null;
        }
        return props.placeholder;
    }
})

const emit = defineEmits(['input'])

////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Use dvalue to get the value for display local date and set the value in iso format.
//
// get: returns the date in local format
// set: emits the date in iso format
//
////////////////////////////////////////////////////////////////////////////////////////////////////
const dvalue = computed({
    get() {
        if (!props.value) {
            return "";
        }
        return date.fmt.local(props.value)
    },
    set: function(v) {
        if (v) {
            v = date.fmt.iso(v)
        }
        emit('input', v)
    }	
})
////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Use picker value for the picker. 
// This is different as dvalue as the picker expects the date in standard format (iso format or Date)
//
// get: returns the date in iso format
// set: emits the date in iso format
//
////////////////////////////////////////////////////////////////////////////////////////////////////
const pickerValue = computed({
    get() {
        if (!props.value) {
            return "";
        }
        return date.fmt.iso(props.value)
    },
    set: function(v) {
        if (v) {
            v = date.fmt.iso(v)
        }
        emit('input', v)
    }	
})

const textfield = ref();
const open = ref(false);

function onClear() {
    dvalue.value = null;
    open.value = false;
}
function onToday() {
    var today = date.iso.today();
    dvalue.value = today;
    open.value = false;
}
function setPicker(a,b,c) {
    open.value = false;
}

/**
 * In                   Out
 * vandaag              2024-05-05
 * today                2024-05-05
 * nu|now|vd|.          2024-05-05
 * 
 * @param {*} v 
 */
var fromPhrase = function(v) {
    if (!v) {
        return null;
    } 
    if (!string.isInRange(v, "nu", "now", "vd", "vandaag", "today", ".")) {
        return null;
    }
    return date.iso.today();
}

////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Handle the blur event. Transfer the control value to the model, and if necesary, 
// change the control value.
// transformations: (assuming the current date is 2024-01-01)
// in:               out:
// +14               15-01-2024
// 1-6-2024          01-06-2024 
// 01-06-2024        01-06-2024 
// 01-06             01-06-2024 
// 
// When offset-source is set, +14 will add 14 days to the offset. 
// For example: 
// source-offset is 2024-02-02
// +14              2024-02-16
// In: +14, Out: '2024-02-02'
//
// In addition: 
// +1m => add one month
// +2w => add two weeks
// 
//
////////////////////////////////////////////////////////////////////////////////////////////////////


var fromOffset = function(v) {
    if (!v) {
        return null;
    } 
    if (!string.contains(v, "+")) {
        return null;
    }
    v = string.replace(v, " ", "");
    var month = string.endsWith(v, "m");
    var week  = string.endsWith(v, "w");
    v = string.replace(v, "m", "");
    v = string.replace(v, "w", "");
    v = Number(v); // "+4"  translates fine.
    if (!v) {
        return null;
    }
    var source = props.offsetSource || date.iso.today();
    if (month) {
        return date.addMonths(source, v);
    }
    if (week) {
        return date.addWeeks(source, v);
    }

    return date.addDays(source, v);
}
// From date
// 1-6-2024          01-06-2024 
// 01-06-2024        01-06-2024 
// 01-06             01-06-2024 
var fromDate = function(v) {
    if (!v) {
        return null;
    } 
    var arrParts = `${v}`.split('-');
    if (!arrParts || arrParts.length < 2 || arrParts.length > 3) {
        return null;
    }
    var d = Number(arrParts[0]);
    var m = Number(arrParts[1]);
    var y = Number(arrParts[2]) || date.year();
    if (y < 2000) {
        y = y + 2000;
    }
    return date.fromYmd(y,m,d);
}

function onBlur(evt) {
    var v = evt?.target?.value;
    if (!v) {
        dvalue.value = null;
    } else {
        v = fromPhrase(v) || fromOffset(v) || fromDate(v);
        dvalue.value = v;
        if (!v) { 
            // scenario: control is empty. THen fill something invalid --> control must be cleared.
            textfield.value.internalValue = null
        } else { 
            // scenario, control contains '2024-07-07' which is today. Now user types '.' to select the current date.
            // the calculated value does not change so visually, the control will still contain the '.'. 
            // Therefore, adjust the field from 'nu' to the representatio value. 
            if (dvalue.value != textfield.value.internalValue) {
                textfield.value.internalValue = dvalue.value;
            }
        }
    }
//    setTimeout(()=>open.value = false, 100)
}
        
function keyDown($event) {
    let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    // 38: key up, 40: key down
    if (!$event || !$event.target) {
        return;
    }
    if (keyCode == 40) { // Key Down --> open the picker
        open.value = true;
    } else if (keyCode == 38) { // Key Up
        open.value = false;
    } else if (keyCode == 27) { // Escape
        open.value = false;
    } else {
        open.value = false;
    }
    // $event.preventDefault();
    // $event.stopPropagation();
}    
</script>

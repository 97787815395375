import { clsModel, fnCreate } from '@cls/clsModel'
import { help as api } from '@/app/api'
import eventbus from '@app/eventbus'

var modelName = "help";

class clsHelp extends clsModel {

    constext=""
    
    constructor() {
        super({
          api: api,   
          modelName: modelName, 
        })
    } 

    /**
     * Override load to retrieve the address via 2 keys - a relation id and an address id.
     * @param {} id 
     * @param {*} params 
     * @returns 
     */
    async load(id, params, extraData) {
        // fill
        console.log('help load', id, params, extraData)
    }
    
    async save() {
        throw new "help save N/A";
    }

 }
 export default fnCreate(clsHelp , 'clsHelp');

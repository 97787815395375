<template>
    <div>    
        <v-card class="pa-0 bg-card mb-2">
            <v-card-text class="pa-0">    
                <LocalTable :model="model" modelLinesProp='lines' ref="lines" no-hover dense :dt="tblLines">
                    <template v-slot:menu ="{ item }">

                        <ActionMenuItem icon="edit" :model="model" action="hourlist.modify" @click="()=>onOpenLine(item)">Open regel</ActionMenuItem>
                        <v-divider></v-divider>
                        <ActionMenuItem icon="remove" :model="model" action="hourlist.modify" @click="()=>onRemoveLine(item)">Verwijder regel</ActionMenuItem>
                    </template>

                    <template v-slot:footer.hr_total_minutes ="{ dt }">
                        <h1>UHU</h1>                        
                    </template>
                    <template v-slot:item.tariff ="{ item }">
                        {{ item.tariff }}
                        <TipMenu v-if="item.tariff && !item.tariff_amount_intern"  warning left ml>
                            <p>Voor deze medewerker is geen bedrag ingevuld voor dit tarief. Er zullen dus geen kosten op het project worden geboekt.</p>
                            <p>Ga naar menu - instellingen - medewerkers of menu - instellingen - tarieven om de bedragen te specificeren.</p> 
                        </TipMenu>

                    </template>

                    <template v-slot:tfoot="{/*dt, hasCmd, */columnHeaders}">
                    <tfoot class="border-bot">
                        <tr>
                            <td></td>
                            <td class="" colspan="3">
                                <span v-if="model?.lines?.length"><span class="text-bold">Contracturen</span>: {{time.minutes2hourdecimal(model.empl_workweek_minutes, false)}}</span>
                            </td>
                            <td class="" :colspan="columnHeaders.length-3">
                                <span class="text-bold" :class="model.totalMinutes<model.empl_workweek_minutes?'text-error':''" v-if="model?.lines?.length">{{ time.minutes2hourdecimal(model.totalMinutes, false) }}</span>
                            </td>
                        </tr>
                        </tfoot>
                    </template>

                </LocalTable>
            </v-card-text>            
        </v-card>
    </div>
</template>



<script setup>

import ActionButton from '@shared/ui/controls/buttons/ActionButton'
import LocalTable from '@datatable/LocalTable'  
import clsLocalTable from '@cls/clsLocalTable'
import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
import {computed, ref, vue, nextTick, watch} from 'vue'
import {date, time} from '@lib/date'
import TipMenu from "@controls/menu/TipMenu.vue";
import noty from '@shared/lib/noty'
import dlg from '@app/dlg'

const props = defineProps({
    model: {
        type: [Object]
    },
});

function fmtDay(v, V, item) {
    return date.fmt.dow(v);
}

function fmtHHMM(v,V,item) {
    if (!item.hr_start_time&&!item.hr_end_time) {
        return '-';
    }
    return time.minutes2hourminutes(v, false);
}
let headers = [
    { type: "cmd" },
    { value: "hr_date"             , text: "Dag"        , fmt: fmtDay, open: (item)=>onOpenLine(item), class:"w-3", },
    { value: "hr_start_time"       , text: "Van"        , fmt:fmtHHMM, class:"w-2_5"},
    { value: "hr_end_time"         , text: "Tot"        , fmt:fmtHHMM, class:"w-2_5"},
    { value: "hr_total_minutes"    , text: "Uren"       , type: 'dhhmm', class:"w-2_5"},
    { value: "hourtype"            , text: "Type"       },
    { value: "tariff"              , text: "Tarief"     },
    { value: "pro_name"            , text: "Project"    },
    { value: "hr_remark"           , text: "Opmerking"  },
];


let tblLines = ref(new clsLocalTable("hourlist.lines", headers, {
    noDataText: "Er zijn nog geen urenregels toegevoegd",
    noSort: true,
    model: props.model, 
    modelLinesProp: 'lines',
    onCheckboxed: (v) =>props.model._disabled=v,
    actionDblClick: (evt,item)=> onOpenLine(item)

}));

async function onRemoveLine(line) {
    if (!await noty.tryConfirm("Weet u zeker dat u deze regel wilt verwijderen?")) {
        return;
    }
    props.model.removeLine(line);
}

async function onOpenLine(line) {
    var result = await dlg.promised.tryOpen('hourline', line);
    if (result) {
        props.model.fill(result);
    }
}

</script>

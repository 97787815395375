import clsEventbus from '@/app/clsEventbus'

// Note that the class implements a couple of method which manipulate the core eventbus object.
// We export the core object to the application.
let obj = new clsEventbus();

// The eventbus is the only object we create a global variable for. 
// Via the eventbus, we can get access to more 'internals' when appropriate. 
if (!window._m) {
    window._m = {
        eventbus: obj
    };
}
export default obj.eventbus;


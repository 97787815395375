import clsHttp from '@/app/clsHttp'

//
// Usage: 
//  import http from '@app/http'
//  var result = http.get(myurl)
//

let obj = new clsHttp();

export default obj;
/**
 * Export all settings which are used in the application. 
 * Since settings are subscribed to data load events, we need to have all settings available at application start.
 * 
 * An instance of this class is returned by @app/settings so that settings can be used as follows: 
 * 
 * import {user} from '@app/settings'
 * import {datatable} from '@app/settings'
 * 
 */

import {ref} from 'vue'

import clsUserSettings       from '@/app/settings/user'
import clsDatatableSettings  from '@cls/clsDatatableSettings'
import clsMandaySettings     from '@/app/settings/manday'
import clsDocumentSettings   from '@/app/settings/document'
import clsInvoiceSettings    from '@/app/settings/invoice'
import clsPurchaseSettings   from '@/app/settings/purchase'
import clsCompanySettings    from '@/app/settings/company'
import clsDashboardSettings    from '@/app/settings/dashboard'
import clsSalesOrderSettings    from '@/app/settings/salesorder'

const user            = ref(new clsUserSettings()).value;
const company         = ref(new clsCompanySettings()).value;
const datatables      = ref(new clsDatatableSettings()).value;
const manday          = ref(new clsMandaySettings()).value;
const document        = ref(new clsDocumentSettings()).value;
const salesinvoice    = ref(new clsInvoiceSettings()).value;
const purchaseinvoice = ref(new clsPurchaseSettings()).value;
const dashboard       = ref(new clsDashboardSettings()).value;
const salesorder      = ref(new clsSalesOrderSettings()).value;

export {
    user                ,
    company             ,
    datatables          ,
    manday              ,
    document            ,
    salesinvoice        ,
    purchaseinvoice     ,
    dashboard           ,
    salesorder          ,
} 
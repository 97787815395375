<template>

    <span>
        {{hm}}            
    </span>

</template>

<script setup>
import {time} from '@lib/date'
import {computed} from 'vue'

/**
 *   import HourMinute from from '@datatable/columns/HourMinute'
 * 
 *   <template v-slot:item.starttime="{ item }">
 *      <HourMinute :value="item.starttime"/>
 *   </template>
 * 
 */
 const props = defineProps({
        value: {
            type: [String, Boolean, Number]
        },
        decimal: {
            type: [Boolean]
        }
    })

    const hm = computed({
        get() {
            if (props.decimal) {
                return time.minutes2hourdecimal(props.value, false)
            }
            // Do not restrict the hh:mm to 24 hours. For datatables, we just show what is in.
            return time.minutes2hourminutes(props.value, false);
        },
    })

</script>

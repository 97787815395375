import clsAuthApi from '@cls/clsAuthApi'
import user from '@app/user'

class clsAuthApiExt extends clsAuthApi {


    onException(e) {
        // In authorization, a 401 is espected. In any other case, log the error. 
        if (e?.response?.status == 401) {
            return; 
        }
        // 422 is not authorized when logging in.
        if (e?.response?.status == 422) {
            return; 
        }
        console.error('clsAuthApi: ', e);
    }

    /**
     * For Optimit, we want the current company to be included when retrieving application data. 
     * 
     * @returns 
     */
    getHeadersForLoadAppData() {
        if (user.getHeaders) {
            return user.getHeaders();
        }

        return [];
    }
    
} 

export default clsAuthApiExt;

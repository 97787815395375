<template>

        <v-card class="bg-card card-blxock container-viewer-outer pdf-viewer-component" outlined>        
            
            <div class="viewer-commands">
                <v-toolbar flat dense class="px-4">
                    <slot name="cmd-left">
                        <span class="title">{{title}}</span>
                    </slot>
                    <v-spacer></v-spacer>

                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.zoomIn()">
                        <Icon small type="zoom-in"></Icon>
                    </v-btn>
                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.zoomOut()">
                        <Icon small type="zoom-out"></Icon>
                    </v-btn>
                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.maximize()">
                        <Icon small type="expand"></Icon>
                    </v-btn>
                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.crop()">
                        <Icon small type="shrink"></Icon>
                    </v-btn>
                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.rotateLeft()">
                        <Icon small type="rotate-left"></Icon>
                    </v-btn>
                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.rotateRight()">
                        <Icon small type="rotate-right"></Icon>
                    </v-btn>
                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.download()">
                        <Icon small type="download"></Icon>
                    </v-btn>

                </v-toolbar>                        

            <v-divider/>
            </div>            
            <div><v-progress-linear class="position-absolute" style="z-index:12;" v-if="isLoading" indeterminate color="primary"></v-progress-linear></div>
            <div id="piViewerContainer" class="position-absolute container-viewer container-viewer-pdf pt-2" ref="container">

                <div id="viewer" class="pdfViewer" align="center"></div>

            </div>
                

        </v-card>

</template>


<script setup>
    //
    // import PDFViewer from '@shared/ui/dialogs/DialogPDF'
    //
    // eventbus.dialog.pdfviewer();
    //
    import string from '@lib/string'
    import clsPdfjs from '@cls/clsPdfjs'
    import Icon from '@controls/icons/Icon'
    import eventbus from '@app/eventbus'
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import {ref, computed, onMounted, watch} from 'vue'

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    var title = ref("");
    let container = ref();  // automagically loads the element with the container reference

    const isLoading = ref(false);
    let viewer = new clsPdfjs({callbackOnLoading: (value) => { isLoading.value = value;}});

    async function load() {        
        if (!props.model.id) {
            // Clear the document. Do not load empty data via loadBase64Data as it will 
            // render empty data as an error document.
            viewer.empty();
            return;
        }
        var fileName = "Inkoopfactuur";
        if (props?.model?.invoicenr) {
            fileName = `Inkoopfactuur ${props?.model?.invoicenr}`;
        }
        viewer.create(container.value);        
        if (window.pv_test_flag_1) {
            // 1) Load data from backend. It requires an extra roundtrip to the server which we do not want.
            viewer.loadData( async () => props.model.downloadData(), fileName)        
        }
        // 2) Load data from client model. 
        else {
            viewer.loadBase64Data( props.model.pdfData(), fileName)        
        }
    } 

    // We want to override only untyped headers. 
    // typed headers are handled by the datatable. 
    var id_model = computed({
        get() {
            return props.model.id;
        }
    })

    watch(id_model, (newValue, oldValue) => {
        load();
    })

    window.vvv = viewer;

</script>

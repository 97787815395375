<template>
    <Dialog :dialog="dlg" :dt="dt" cmd-menu>

        <template v-slot:filter="{onFocus}">
            <TextField @focus="onFocus" class="" ref="q" dusk="select-search" block max-length="255" placeholder="Zoek..." v-model="dt.filter.q"></TextField>
        </template>

        <template v-slot:item.amount="{item}">
            <NumberField decimal dusk='amount' v-model='item.amount'></NumberField>
        </template>

        <template v-slot:cmd-menu>
            <ActionMenuItem dusk="menu-create-product" no-execute @click="onCreateProduct" action='product.create'></ActionMenuItem>
            <ActionMenuItem dusk="menu-create-recipe"  no-execute @click="onCreateRecipe" action='recipe.create'></ActionMenuItem>
        </template>

    </Dialog>
</template>



<script setup>

    import useDataTable from '@app/useDataTable'
    import string from '@lib/string'

    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/SelectListDialog'
    import { products as api } from '@app/api'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsSelectListDialog'
    import Constants from '@app/consts'
    import action from '@app/action'
    import ActionMenuItem from '@controls/menu/ActionMenuItem.vue'
    import appDlg from '@app/dlg'
    import feature from '@app/features';
    import NumberField from '@controls/input/NumberField'
    
    const form = ref(null); 
    const id_optimit_type = Constants.optimit_types.product;
    const q = ref(null)

    var headers = [
        { type: 'cmd'},
        { type: "note", noteType: 'product_note' },
		{ visible: false, text: "Aantal",                    value: "amount",   class: 'w-1', cellClass:'px-1' }, 
		{ text: "Naam",                      value: "pd_name"                          }, 
		{ text: "Code",                      value: "pd_code"                          }, 
		{ text: "Leverancier",               value: "sup_name"                         }, 
		{ text: "Leverancierscode",          value: "pd_supplier_code",                visible: false}, 
		{ text: "Leveranciersnaam",          value: "pd_supplier_name",                visible: false}, 
		{ text: "Inkoopprijs",               value: "pd_purchase_price",               type: 'amount', visible: false}, 
		{ text: "Verkoopprijs excl. BTW",    value: "pd_sales_price_excl_vat",         type: 'amount'}, 
        ];
        if (feature.canConsumerPrice) {
            headers.push({ text: "Consumentenprijs",    value: "pd_sales_price_incl_vat",         type: 'amount', visible: false});
        }
        headers = [...headers, ...[
		{ text: "BTW Type",                  value: "vat_name",                        visible: false}, 
		{ text: "Groep",                     value: "group",                           visible: false}, 
		{ text: "Eenheid",                   value: "un_name",                         visible: false}, 
		{ text: "Grootboek",                 value: "led_name",                        visible: false}, 
		{ text: "Aangemaakt door",          value: "created_by",                       visible: false, },
		{ text: "Laatste update door",      value: "updated_by",                       visible: false, },
		{ text: "Aangemaakt",               value: "created_at",                       visible: false, type: 'datetime'},
		{ text: "Laatste update",           value: "updated_at",                       visible: false, type: 'datetime'},

    ]];

    var dt = useDataTable("dt_select_product", "product", api, headers, {
        actionDblClick:null,
        itemName: {
            prefix: 'het',
            single: 'artikel',
            plural: 'artikelen',
        }, 
        useCommandPane: false,
        canArchive: false,
        filter: {
            type: '',
            selectfor: '',
        },
        onAfterSearch: (data, dt) => {
            (data?.rows||[]).forEach( (item) => item.amount = 1);
        }
    })

    let dlg = ref(
        new clsDialog("select_product", "Selecteer artikel", dt, {
            multiselect: false, 
            maxWidth:"1200px", 
            defaultFocus: ()=>q.value
        })).value;

    /**
     * Create a new product
     * And, when saved, select it.
     */
    async function onCreateProduct() {
        try {
            var result = await appDlg.promised.create("product");
            dlg.onSelected([result]);
        } catch (e) {  } // canceled.
    }

    /**
     * Create a new recipe
     * And, when saved, select it.
     */
     async function onCreateRecipe() {
        try {
            var result = await appDlg.promised.create("recipe");
            dlg.onSelected([result]);
        } catch (e) {  } // canceled.
    }
    window.spdlg = dlg;
</script>

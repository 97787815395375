<template>
    <v-row dense class="toggle-button">
        <template v-for="(option, index) in options" >
            <v-col :key="index" :class="{'col-auto':colAuto}">
                <v-btn block :disabled="compDisabled||compSkeleton"
                    outlined
                    @click="dvalue=option.id"
                    :color="isValueSet(dvalue,option.id)?'primary':''"
                    :class="isValueSet(dvalue,option.id)?'selected':'unselected'" >
                    {{option.name}}      
                </v-btn> 
            </v-col>
        </template>
    </v-row>
</template>

<script setup>

import bool from '@shared/lib/bool'

// Usage: 
// import ToggleButtonGroup from '@controls/buttons/ToggleButtonGroup'
// <ToggleButtonGroup col-auto :model="model" v-model="model.type" :options="[{id: 'invoice', name: 'Factuur'}, {id: 'pin', name: 'Pinbon'}, {id: 'cash', name: 'Kasbon'}]"/>

let roptions = [
    {id: 'one', name: 'Een'}, 
    {id: 'two', name: 'Twee'}, 
    {id: 'three', name: 'Drie'}, 
]

import {computed, ref} from 'vue'            

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        colAuto: {
            type: [Boolean]
        },
        bool: {
            type: [Boolean]
        },
        value: {
            type: [String, Number, Date, Boolean]
        },
        options: {
            type: [Array]
        },
        model: {
            type: [Object]
        }
    })

const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

    const emit = defineEmits(['input'])
    const dvalue = computed({
        get() {
            if (!props.bool) {
                return props.value                
            }
            return bool.isTrue(props.value)
        },
        set: function(v) {
            v = props.bool ? bool.isTrue(v) : v;
            emit('input', v)
        }	
    })

    function isValueSet(value, myValue) {
        if (props.bool) {
            return bool.compare(value, myValue)                
        }
        return value == myValue;
    }

</script>

<template>
    <div>
        
        
        <LocalTable :model="model" modelLinesProp='lines' editable ref="lines" no-hover dense :dt="tblLines">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem :model="model" :disabled="item.isInvoiced" action="salesinvoice.create" :payload="item" @click="onStartCreateInvoice(item)">Maak factuur</ActionMenuItem>
                <v-divider></v-divider>
                <ActionMenuItem :model="model" action="salesorder.modify" icon="remove" @click="()=>onRemoveLine(item)">Verwijder regel</ActionMenuItem>
            </template>

            
            <template v-slot:item.ol_date ="{ item }">        
                <DatePicker :textError="!item.isInvoiced&&item.isExpired" no-border :model='model' v-model='item.ol_date'></DatePicker>
            </template>
            <template v-slot:item.inv_number ="{ item }">        
                <DrillDown open="salesinvoice" :id="item.id_invoice" :rep="item.inv_number" empty="-"></DrillDown> 
            </template>

            <template v-slot:item.ol_amount ="{ item }">        
                <NumberField no-border decimal :model='model' v-model='item.ol_amount' ></NumberField>                        
            </template>
            <template v-slot:item.id_unity ="{ item }">        
                <UnitCombo no-border :model='model' v-model='item.id_unity'></UnitCombo>
            </template>
            <template v-slot:item.ol_pd_name ="{ item }">        
                <RichTextLine dusk="name" no-border :model="model" placeholder="Omschrijving" v-model="item.ol_pd_name"></RichTextLine>
            </template>
            <template v-slot:item.id_product ="{ item }">        
                <ProductPicker no-border @expand="()=>onExpand(item)"  dusk="product" :field-map="productSelectFieldMap" :filter="{'type': 'rp', 'selectfor':'salesinvoice'}" :model='model' :valueModel="item" v-model='item.id_product'></ProductPicker>
            </template>
            <template v-slot:item.ol_sales_price ="{ item }">        
                <NumberField no-border amount :model='model' v-model='item.ol_sales_price' :currency='model.crnc_code'></NumberField>                        
            </template>
            <template v-slot:item.id_vat ="{ item }">                                
                <span class="ml-2 txt" v-if="model.vat_shifted">Verlegd</span>
                <VatCombo v-else dusk="vat" no-clearable standard-types no-border :model='model' :rules='model.rules.mandatory' v-model='item.id_vat'></VatCombo>
            </template>
            <template v-slot:item.ol_sub_total ="{ item }">                                
                <AmountText class="" :value="item.ol_sub_total" currency="EUR"></AmountText>
            </template>

            <template v-slot:commands="{dt,bulkAction, selectedCount}">
                <ActionButton main primary icon="remove" v-if="bulkAction=='remove'"  @click="()=>onRemoveLines(dt)" :disabled="!selectedCount" >Verwijder</ActionButton>
                <ActionButton main primary icon="invoice" v-if="bulkAction=='createInvoice'"  @click="()=>onCreateInvoice(dt)" :disabled="!selectedCount" >Maak factuur</ActionButton>
            </template>

            <template v-slot:footer.ol_sub_total ="{ dt }">
                <AmountText class="text-bold" :value="model.total" currency="EUR"></AmountText>                                
            </template>

            <template v-slot:header.ol_sub_total="{datatable}">
                <span>
                    <span v-if="model.useAmountsExclVat">Totaal Excl. BTW</span>
                    <span v-else class="text-error">Totaal Incl. BTW</span>
                </span>                
            </template>

            <template v-slot:tfoot="{/*dt, hasCmd, */columnHeaders}">
            <tfoot >
                <tr>
                    <td class="pt-4 pb-4 pl-4" :colspan="columnHeaders.length-3">
                        <span class="position-absolute">
                            <ActionButton sub action="salesorder.modify" dusk="btn-add-line" :model="model" icon="add" @click='(evt)=>addLine(evt)'>Nieuwe regel</ActionButton>
                            <ActionButton sub action="salesorder.modify" dusk="btn-add-product" :model="model" ml icon="product" @click='(evt)=>addProductLine(evt)'>Artikel</ActionButton>
                            <ActionButton sub action="salesorder.modify" dusk="btn-add-term" :model="model" ml icon="order" @click='(evt)=>addTerm(evt)'>Termijn</ActionButton>
                        </span>
                        <span class="min-h-16"></span>
                    </td>
                    <td colspan="4" class="text-right text-bold pr-3 pt-1" >    
                        <span class="mt-2">
                            <span class="" v-if="model.useAmountsExclVat">Totaal Excl. BTW</span>
                            <span class="" v-else >Totaal Incl. BTW</span>
                            <span class="ml-4 text-secondary">
                                <AmountText v-model='model.ord_amount' :currency='model.currency'/>
                            </span>

                        </span>
                    </td>
                </tr>
                
                </tfoot>
            </template>



        </LocalTable>

    </div>
</template>



<script setup>

import ActionButton from '@shared/ui/controls/buttons/ActionButton'
import LocalTable from '@datatable/LocalTable'  
import clsLocalTable from '@cls/clsLocalTable'
import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
import {computed, ref, vue, nextTick, watch} from 'vue'

import AmountText from '@controls/text/Amount.vue'
import NumberField from '@controls/input/NumberField'
import RichTextLine from '@controls/input/RichTextLine'
import UnitCombo from '@controls/combo/Unit'    
import ProductPicker from '@controls/picker/Product'
import DatePicker from '@controls/input/DatePicker'
import VatCombo from '@controls/combo/Vat'
import dlg from '@app/dlg'
import DrillDown from '@controls/buttons/DrillDown'

import TextField from '@controls/input/TextField'
import Checkbox from "@controls/check/Checkbox.vue";
import IconMenu from "@controls/menu/IconMenu.vue";
import Icon from '@controls/icons/Icon'
import ActionMenu from '@controls/menu/ActionMenu.vue'
import FormRow from '@controls/forms/FormRow'
import noty from '@shared/lib/noty'

// Take over the following fields from the corresponding properties in the selected item: 
const productSelectFieldMap = {
//  selectfield:              invoiceline field
    pd_name:                  'ol_pd_name',
    pd_supplier_code:         'pd_supplier_code',
    pd_supplier_name:         'pd_supplier_name',
    pd_sales_price_excl_vat:  'ol_sales_price', 
    id_vat:                   'id_vat',
    id_unity:                 'id_unity',
    sup_name:                 'sup_name',
};

const props = defineProps({
    model: {
        type: [Object]
    },
    invTriggerCnt: {
        type: [Number, String]
    }
});
const emit = defineEmits(['onCreateInvoice', 'save'])

let headers = [

    { canhide: false, canmove: false, type: 'cmd'}, 
//    { text: 'Status',                                    value: 'status',                   class:'w-2 text-right pr-3'},
    { text: 'Aantal',                                    value: 'ol_amount',                class:'w-2 text-right pr-3'},
    { visible: false, text: 'Eenheid', configText: 'Eenheid',            value: 'id_unity', class:'w-min-3 w-3 pl-3'},
    { text: 'Omschrijving',                              value: 'ol_pd_name',               class:'w-min-3 w-max-10 pl-3'   },
    { visible: false, text: 'Artikel',                   value: 'id_product',               class:'w-min-3 w-5 pl-3'},
    { visible: false, text: 'Fabrikant',                 value: 'sup_name',                 class: 'w-3 pl-3',        cellClass: 'px-3'},
    { visible: false, text: 'Fabrikantcode',             value: 'pd_supplier_code',         class: 'w-3 pl-3',        cellClass: 'px-3'},
    { visible: false, text: 'Fabrikantnaam',             value: 'pd_supplier_name',         class: 'w-3 pl-3',        cellClass: 'px-3'},
    { canhide: false, text: 'Factuurdatum',              value: 'ol_date',                  class:'w-min-3_5 w-3_5 pl-3'},
    { canhide: false, text: 'Factuur',                   value: 'inv_number',               class:'pl-3 w-min-2 w-2', cellClass: 'px-3',},
    { canhide: false, text: 'Prijs',                     value: 'ol_sales_price',           class:'w-min-3 w-3 text-right pr-3'},
    { canhide: false, text: "BTW",                       value: "id_vat",                   class:"w-min-3 w-3 pl-3"}, 
    { canhide: false, text: "Totaal Excl. BTW",          value: "line_total",               type: 'amount', cellClass:"text-right pr-3", class:"w-3 text-right pr-3"}, 

];


let tblLines = ref(new clsLocalTable("salesorder.lines", headers, {
    noDataText: "Er zijn nog geen regels toegevoegd",
    noSort: true,
    model: props.model, 
    modelLinesProp: 'lines',
    onCheckboxed: (v) =>props.model._disabled=v

}));

window.olns = tblLines

function addLine(evt) {
    let index = props.model.addLine();
//    dlg.setFocusToFirstInputInLastLine(evt);    
//     setTimeout( ()=>setLineFocus(index), 0)
}
async function addTerm(){
    try {
        let selected = await dlg.promised.create("salesorder_createterm", {multiselect:false})
        if (selected && selected.id_order_term) {
            props.model.addTerms(selected)
        }
    }
    catch (e) { /* canceled */}
}
async function addProductLine() {
    try {
        var fnMultiSelectCallback = function(selected) {
            if (!selected || !selected.length) {
                return;
            }
            props.model.addProductLine(selected[0]);                
        }

        let selected = await dlg.promised.open("select_product", {multiselect:false, fnMultiSelectCallback: fnMultiSelectCallback, filter: {type: 'rp'}}) // rp: recipes and products
        if (!selected || !selected.length) {
            return;
        }
        props.model.addProductLine(selected[0]);
    } catch (e) { return; }
}


function onRemoveLine(line) {
    props.model.removeLine(line);
}

//
// Save the data. Then set the datatable in checkbox modus so that one or more lines can be selected 
//
async function onStartCreateInvoice(line) {
    var id = line?.id;
    
    try {
        await new Promise((resolve, reject) => emit('save', resolve, reject));
        // The data is saved and hence, re-populated. The original line is not the same object as what we are looking for. 
        // Therefore, re-retrieve the line from the table data.
        // Note that this does not work for just inserted lines as such a line has no original id.
        line = tblLines.value.findLineById(id);
        tblLines.value.onStartCheckBox(line, "createInvoice");
    } catch (e) {
        console.log(e);
        if (!e.canceled) { throw e;}
    }

}

async function onCreateInvoice(dt) {
    var lines = dt.selectedIDs();
    if (lines && lines.length) {

        var s = (lines.length == 1) ? "" : "s";
        if (!await noty.tryConfirm(`Weet u zeker dat u voor deze regel${s} een factuur wilt maken?<br><br>Er wordt een concept factuur gemaakt, dus deze wordt <strong>niet</strong> automatisch verzonden.<br>Voordat u de factuur verstuurt kunt u deze nog aanpassen.<br><br>De factuur wordt na het aanmaken geopend.`)) {
            return;
        }

        var id_invoice = await props.model.createInvoice(lines)
        dlg.open("salesinvoice", {id: id_invoice});
    }
    dt.onStopCheckbox();
}

const compInvTrigger = computed({
        get() {
            return props.invTriggerCnt
        }
    })

watch(compInvTrigger, (newValue, oldValue) => {
    // console.log("Watch invTriggerCnt", newValue, oldValue)
    onStartCreateInvoice();
})



</script>

<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <TextArea :rows="12" :model="model" dusk="note" :skeleton="model.isDataLoading" placeholder="Notitie" v-model="model.note"></TextArea>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/note'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextArea from '@controls/input/TextArea'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import string from '@lib/string'
    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 

    class clsNoteDialog extends clsDialog {
        originator = null;

        onOpen(params, extraData) {
            this.originator = params;
            return super.onOpen(params, extraData);
        }

        // Override to execute actions after save;
//        async onAfterSave() {
//            this.originator.noteCount = string.isEmpty(this.model.note) ? 0 : 1;
//            if (this.originator.sendSavedEvent) {
//                this.originator.sendSavedEvent();
//            }
//        }

    }

    let model = useModel();
    let dlg = ref(
        new clsNoteDialog("note", "Notitie", model, [form], 
            {
                maxWidth:850
            }
        )).value;

    window.dlgn = dlg;

</script>

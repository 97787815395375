import Vue from 'vue'
import VueRouter from 'vue-router'
import user from '@app/user'
import routing from '@app/routing'
import noty from '@shared/lib/noty'
import dlg from '@app/dlg'

// The router is a vue framework feature. 
// We don't want to adjust it, only extend it to use our authorisation needs. 
// For application specific authorisation stuff, we use the routing object, which is imported above.
// As we can re-use features from the core router in the routing object (e.g. resolving a route by name), 
// We giv routing a reference to this by calling setVueRouter. 
//
// Note that all routing functionallity in the application is handled by the routing object.    
//      So, the application does NOT access this router file directly. 


//let routes = navigation.routesInRouterFormat();

let routes = routing.getRoutes();

Vue.use(VueRouter)

const routeUnknown =
{
    path: '*',
    name: 'route.unknown',
    component: () => import('@/views/system/404.vue')
};

const _routes = [...routes, routeUnknown];

// The path which we defer to when the user is logged in but has no access to the chosen page
// This is the default path an authorized user is sent to after login or after an unavailable route is accessed.
const routeDefaultAuthorized = "dashboard";
// THe login route
const routeLogin = "auth.login";
// THe path to use in case the user has no administrations
const routeNoAdministrations = "no.administrations"
// The route to use when a user must select an administration
const routeSelectAdministration  = "select.administration"
const routeSubscriptionSuspended = "subscription.suspended"
const routeSubscriptionCanceled  = "subscription.canceled"
const routeTrialPeriodExpired    = "subscription.trialperiodexpired"

// The path which we defer to when the user is logged in but has no access to the chosen page
//const unAuthorizedPath = { path: "/"};

const router = new VueRouter({
    routes: _routes
})

// See the comment at the top of the file.
routing.setVueRouter(router);

router.debug = function(x) {
    if (undefined == x) {
        x = true;
    }
    router._debug = !!x;
}

/**
 * Authentication
 */

function log(msg, a, b, c) {
    if (!router._debug) {
        return;
    }
    console.log(msg, a, b, c);
}

/**
 * Handle the route for logged in users. 
 * A logged in user: 
 *     - is fully logged in, which means that the logged in process is complete and also, an administration is selected. 
 * 
 * @param {*} next 
 */
async function handleRouteForLoggedInUser(to, from, next) {

    function _log(msg, a,b,c) {
        log('handleRouteForLoggedInUser', msg,a,b,c);
    }
    _log('to', to);
    _log('from', from);

    // When the root is accessed, defer to the dashboard.
    if (to.path == "/") {
        next(routing.to(routeDefaultAuthorized))
        return;
    }
    if (!user.isSubscriptionTypeNone) {
        if (user.isSubscriptionCanceledFinal && to.name != 'subscription.canceled') {
            next(routing.to(routeSubscriptionCanceled))
            return;
        }
        if (user.isTrialPeriodEnded && to.name != 'subscription.trialperiodexpired') {
            next(routing.to(routeTrialPeriodExpired))
            return;
        }    
    }
    if (user.isSubscriptionSuspended  && to.name != 'subscription.suspended') {
        next(routing.to(routeSubscriptionSuspended))
        return;
    }
    
    // Should a user be logged in to access this route?
    const isAuthenticatedRoute = routing.shouldUserBeLoggedInFor(to);
        
    // If the route does not need authentication, fine. Proceed.
    if (!isAuthenticatedRoute) {
        _log('Enter unauthenticated route.')
        next(); 
        return; // verrrrry important
    }   

    // Is the current user authorised to access the given route?
    if (routing.isUserAuthorizedFor(to)) {
        _log('Route access granted.')
        next(); 
        return; // very important
    }

    // Otherwise, the route is authorized and we can not access it.
    var defaultRoute = routing.to(routeDefaultAuthorized)
    _log('Route access denied. Deferring to: ', defaultRoute)
    next(defaultRoute); 
}

/**
 * Handle the route for a not logged in user. 
 * Note: the user is not logged in for frontend point of view. 
 *       That is, a user might be logged in, but has hit F5 to refresh.
 *       And of course, when the user is not logged in, this is also the entry point.
 *  
 * @param {*} to 
 * @param {*} next 
 */
async function handleRouteForNotLoggedInUser(to, from, next) {
    function _log(msg, a,b,c) {
        log('handleRouteForNotLoggedInUser', msg,a,b,c);
    }

    // The login route
//    const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
    const loginQuery = { path: "/login" };

    // Should a user be logged in to access this route?
    const isAuthenticatedRoute = routing.shouldUserBeLoggedInFor(to);
    
    // If the route does not need authentication, fine. Proceed.
    if (!isAuthenticatedRoute) {
        _log('Enter unauthenticated route.')
        next(); 
        return; // verrrrry important
    }

    // 1) The user is not loaded at all
    //    --> call checkLoggedIn for the user.
    //        this call will retrieve the user data from the server. 
    //    a --> When after this call, the user is still nog logged in ==> defer to login route. 
    //    b --> Otherwise, fall though to the following situations.
    // 
    // 2) The user is loaded but no administrations are availabe
    //    --> defer to 'no administrations available' page.
    // 
    // 3) The user is loaded, administrations are available but no administration is selected yet
    //    a) - the user has multiple adminstrations --> defer to select administration page
    //    b) - the user has only one administration --> select it.
    // 
    //

    // 1) 
    if (!user.isLoggedIn()) {
        var preferredAdministration = to && to.params && to.params.id_administration;
//        console.log('checking for preferred Adm: ', preferredAdministration, to)
        await user.checkIsLoggedIn(preferredAdministration);
    }

    // 1a) ==> We have checked but we are not logged in. Defer to login page. 
    if (!user.isLoggedIn()) {
        _log('Not loggedin. Deferring to login page.')
        next(routing.to(routeLogin));
        return;
    }

    if (!user.hasAdministrationSelected) {
        // 2) When the user has no administrations available, defer to no rights page.
        if (user.hasNoAdministration) {
            _log('No administrations available.')
            next(routing.to(routeNoAdministrations)); 
            return; // very important
        }
        
        // 3)a
        if (user.hasMultipleAdministrations) {
            _log('Multiple administrations availble. Select first.')
            next(routing.to(routeSelectAdministration)); 
            return; // very important
        }

        // Else, a problem occured loading the one administration. 
        noty.snack.error("Het laden van de applicatie is mislukt");
        next(routing.to(routeLogin));
        return;
    }

    // When one administration was available, it is selected and loaded by the user already. 

    //    // 3b load the administration. On failure, defer to login.
    //    var result = await user.selectAdministration();
    //    if (!result.success) {
    //        _log('Selected single administration. Failed.');
    //        eventbus.snackbar.error({text: result.message || "Het laden van de applicatie is mislukt"});
    //        next(routing.to(routeLogin));
    //        return;
    //    }    
    //    _log('Selected single administration.');

    // At this stage, we are logged in and an administration is selected. 
    // We can let the logged in route handler do the rest.
    return handleRouteForLoggedInUser(to, from, next);
    
}

/**
 * Filter or passthrough the routing depending on the login status / route selection
 */
router.beforeEach( async (to, from, next) => {

    // Before a route is selected, make sure that all dialogs are closed.
    dlg.close();    

    if (user.hasMultipleAdministrationsButNoneSelected) {
        // When select or login path are selected, let the user proceed
        let selectpath = '/select-administration';
        let loginPath = '/login';
        if (to.path == selectpath || to.path == loginPath) {
            next();
        } else {
            next(selectpath); 
        }
        return; // very important
    }
    if (user.hasAdministrationSelected) {
        return handleRouteForLoggedInUser(to, from, next);
    }
    return handleRouteForNotLoggedInUser(to, from, next);
});

/*
router.beforeEach( (to, from, next) => {
    // isLoggedIn means that the user is loggedin, not that the user is still logged in. 
    // In the latter case, each request will lead to an authentication failure which wil get us logged out.
    // However, from router point of view we just need to know if we are logged in.
    const isLoggedIn = user.isLoggedIn();
    // does the route require authentication?
    // This is the default case, only when explicitely set to false, we do not require authentication. 
    const isAuthenticatedRoute = to.matched.some((record) => record.meta.requiresAuth !== false);

    console.log('route, isAuthenticatedRoute, isLoggedin', to.path, isAuthenticatedRoute, isLoggedIn)

      
    // If the route does not need authentication or the user is logged in, fine. Proceed.
    if (!isAuthenticatedRoute || isLoggedIn) {
        return; // Fine!
    }

    next()
})
*/
/* Once router v4 is used when switching to vue 3, the following is the implemetation:

router.beforeEach( async (to, from) => {
    // isLoggedIn means that the user is loggedin, not that the user is still logged in. 
    // In the latter case, each request will lead to an authentication failure which wil get us logged out.
    // However, from router point of view we just need to know if we are logged in.
    const isLoggedIn = user.isLoggedIn();
    // does the route require authentication?
    // This is the default case, only when explicitely set to false, we do not require authentication. 
    const isAuthenticatedRoute = to.matched.some((record) => record.meta.requiresAuth !== false);

    console.log('route, isAuthenticatedRoute, isLoggedin', to.path, isAuthenticatedRoute, isLoggedIn)

  
    // If the route does not need authentication or the user is logged in, fine. Proceed.
    if (!isAuthenticatedRoute || isLoggedIn) {
        return; // Fine!
    }

    // Otherwise check if the user is logged in but did e.g. refresh or come back after redirect  
//    await user.checkIsLoggedIn();
    if (user.isLoggedIn()) {
        return; // Fine!
    }    

    console.log('deferring route')
  
    // The path to the login route.
    const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
  
    // otherwise, we are not logged in.
    return loginQuery; // redirect to login.
  });
*/

export default router

import { clsModel, fnCreate } from '@cls/clsModel'
import { hourline as api } from '@/app/api'
import { tariff as tarifflist, hourtype as hourtypelist, employee as employeelist} from '@app/list';
import {date} from '@lib/date'

var modelName = "hourline";
const fields = [
	"id", 
	"hl_week", 
	"hl_year", 
	"pro_name", 
	"pro_number", 
	"hr_date", 
	"hr_start_time", 
	"hr_end_time", 
	"hr_remark", 
	"hr_total_minutes", 
	"id_project_chapter", 
	"id_project", 
	"id_hour_type", 
	"id_hourlist", 
	"id_tariff", 
	"tariff_amount_intern", 
	"dayofweek", 
];

class clsHourLine extends clsModel {

    hl_week = null;
    hl_year = null;

    _id_hour_type = null;
    _hr_start_time = null;
    _hr_end_time = null;
    hr_total_minutes = 0;
    hr_remark = null;    
    id_tariff = null;
    _specify_start_end_time = true;

    id_project = null;
    pro_name = null;
    pro_number = null;
    id_project_chapter = null; 
    ch_name = null; 
    ch_rep = null;

    dayofweek = 0;
	tariff_amount_intern = null

    get hr_start_time() {return this._hr_start_time;}
    get hr_end_time() {return this._hr_end_time;}
    set hr_start_time(v) {
        this._hr_start_time = v;
        if (!this.isFilling) {
            this.calculateWorkingHours();
        }
    }
    set hr_end_time(v) {
        this._hr_end_time = v;
        if (!this.isFilling) {
            this.calculateWorkingHours();
        }
    }
    calculateWorkingHours() {
        var mFrom = this._hr_start_time || 0;
        var mTo   = this._hr_end_time || 0;
        this.hr_total_minutes = Math.max(0, mTo - mFrom);
    }
    get isForProject() {
        if (!this.id_hour_type) {
            return false;
        }
        return !!hourtypelist.oneProp(this.id_hour_type, 'project')
    }

    get specify_start_end_time()  { return this._specify_start_end_time;} 
    set specify_start_end_time(v) { 
        this._specify_start_end_time = v;
        if (!v) {
            if (!this.id_tariff) {
                if (tarifflist?.list?.length) {
                    this.id_tariff = tarifflist.list[0].id;
                }
            }
        }
    } 

    get id_hour_type() {
        return this._id_hour_type;
    }
    set id_hour_type(v) {
        this._id_hour_type = v;
        if (!this.isForProject) {
            this.id_project = null;
            this.pro_name = null;
            this.pro_number = null;
            this.id_project_chapter = null; 
            this.ch_name = null; 
            this.ch_rep = null;
        }
    }

    // Get the date 
    yyww2Date(offset) {
        return date.isoYearWeekToDate(this.hl_year, this.hl_week, offset||0)
    }
    fmtDateShort(d) {
        if (!d) {
            return "";
        }
        if (d.getTime && !d.getTime()) {
            return "";
        }
        return date.fmt.custom(d, "DD MMM");
    }

//    yyww2DateShort(offset) {
//        var dt = this.yyww2Date(offset);
//        return date.fmt.custom("DD MMM");
//    }
    get mon() { return this.dayofweek == 0} set mon(x) { this.dayofweek = 0}
    get tue() { return this.dayofweek == 1} set tue(x) { this.dayofweek = 1}
    get wed() { return this.dayofweek == 2} set wed(x) { this.dayofweek = 2}
    get thu() { return this.dayofweek == 3} set thu(x) { this.dayofweek = 3}
    get fri() { return this.dayofweek == 4} set fri(x) { this.dayofweek = 4}
    get sat() { return this.dayofweek == 5} set sat(x) { this.dayofweek = 5}
    get sun() { return this.dayofweek == 6} set sun(x) { this.dayofweek = 6}
    get fmt_date_mon() { return this.fmtDateShort(this.yyww2Date(0)); }
    get fmt_date_tue() { return this.fmtDateShort(this.yyww2Date(1)); }
    get fmt_date_wed() { return this.fmtDateShort(this.yyww2Date(2)); }
    get fmt_date_thu() { return this.fmtDateShort(this.yyww2Date(3)); }
    get fmt_date_fri() { return this.fmtDateShort(this.yyww2Date(4)); }
    get fmt_date_sat() { return this.fmtDateShort(this.yyww2Date(5)); }
    get fmt_date_sun() { return this.fmtDateShort(this.yyww2Date(6)); }
    
    get modelRep() {
        return null; // this.ht_name;
    }

    fill(data) {
        data = data ||{};
        data.ht_project = !!data.ht_project;        
        var result = super.fill(data);
        this._specify_start_end_time = !!this.hr_start_time || !!this.hr_end_time;
        return result;
    }
    
    constructor() {
        super({
          api: api,   
          modelName: modelName, 

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsHourLine , 'clsHourLine');

import { clsModel, fnCreate } from '@cls/clsModel'
import { salesinvoices as api, recipes as apiRecipe } from '@/app/api'
import Constants from '@app/consts'
import string from '@lib/string'
import {date} from '@lib/date'
import {relation, vat as vatlist, unit as unitlist} from '@app/list';
import vat from '@lib/vat'
import {salesinvoice as settings} from '@app/settings'
import { numeric, percentage } from '@lib/numeric'
import feature from '@app/features';
import noty from '@shared/lib/noty'
import eventbus from '@app/eventbus'

// The action via which a credit invoice was requested.
const ACTION_CREDIT = 'salesinvoice.credit';
const ACTION_COPY   = 'salesinvoice.copy';

var modelName = "salesinvoice";
const mandatoryFields = ["id_relation", "inv_name", "inv_date", "inv_exp_date"];

const id_optimit_type = Constants.optimit_types.salesinvoice;
const fields = [
    "id", 
    "id_relation", 
    "id_project", 
    "pro_name", 
    "pro_number",
    "id_project_chapter", 
    "ch_name", 
    "ch_rep",
    "id_person", 
    "inv_number", 
    "inv_date", 
    "inv_exp_date", 
    "inv_book_date", 
    "inv_name", 
    "inv_rel_name", 
    "inv_sub_total", 
    "inv_vat_total", 
    "inv_total", 
    "inv_status", 
    "inv_comment", 
    "inv_reference", 
    "inv_use_g_account", 
    'use_payment_link',
    "inv_g_account_type", 
    "inv_g_account_perc", 
    "inv_wage", 
    "inv_g_amount", 
    "inv_vat_shifted", 
    "inv_total_paid", 
    "reminder1_date",
    "reminder2_date",
    "defaultnotice_date",
    "inv_lines_incl_excl_vat", 
    'lines',
];

class clsInvoiceLine {
    id = null;
    ihp_type = null;
    ihp_amount = null;
    ihp_pd_name = null;
    ihp_sales_price = null;
    ihp_discount = null;
    id_product = null;
    id_mandays_registration = null;
    _id_vat = null;
    id_unity = null;
    id_project_extra_work = null;
    id_project = null;
    pro_name = null;
    pro_number = null;
    id_project_chapter = null; 
    ch_name = null; 
    ch_rep = null;
    pd_name = null;
    pd_code = null;
    pd_supplier_code = null;
    pd_supplier_name = null;
    ean = null;
    ord_name = null;
    sup_name = null;
    mdr_number = null;

    id_order_line = null;
    id_order = null;
    ord_number = null;
    ord_name = null;

    constructor(data) {        
        data = data || {};
        this._id_vat = data.id_vat || Constants.defaults.id_vat;
        this.id = data.id || null;
        this.ihp_type = data.ihp_type || "invoice_product";
        this.ihp_amount = data.ihp_amount || 1;
        this.ihp_pd_name = data.ihp_pd_name || null;
        this.ihp_sales_price = data.ihp_sales_price || 0;
        this.ihp_discount = data.ihp_discount || 0;
        this.id_product = data.id_product || null;
        this.id_mandays_registration = data.id_mandays_registration || null;
        this.mdr_number = data.mdr_number || null;
        this.id_unity = data.id_unity || null;
        this.id_project_extra_work = data.id_project_extra_work || null;
        this.id_project = data.id_project || null;
        this.pro_name = data.pro_name || null;
        this.pro_number = data.pro_number || null;
        this.pd_name = data.pd_name || null;
        this.pd_code = data.pd_code || null;
        this.pd_supplier_code = data.pd_supplier_code || null;
        this.pd_supplier_name = data.pd_supplier_name || null;
        this.ean = data.ean || null;
        this.ord_name = data.ord_name || null;
        this.sup_name = data.sup_name || null;
        this.mdr_number = data.mdr_number || null;
        this.id_project_chapter = data.id_project_chapter || null;
        this.ch_rep = data.ch_rep || null;

        this.id_order_line = data.id_order_line || null;
        this.id_order = data.id_order || null;
        this.ord_number = data.ord_number || null;
        this.ord_name = data.ord_name || null;
    
    }   

    get amount_excl() { 
        var pctFactor = 1; // By default, no percentage.
        if (this.id_unity) {
            let unity = unitlist.one(this.id_unity);            
            if (!!unity.is_percentage) {
                pctFactor = 0.01;
            }
        }
        let price = this.ihp_sales_price;
        if (Number(this.ihp_discount)) {
            price = ( 1 - (Number(this.ihp_discount)/100)) * price;
        }
        return numeric.round(pctFactor * this.ihp_amount * price,2);
    }
    get id_vat() { return this._id_vat;}
    set id_vat(value) { this._id_vat = value;}

    setProject(id_project, pro_number, id_project_chapter, ch_rep) {
        this.id_project = id_project;
        this.pro_number = pro_number;    
        this.id_project_chapter = id_project_chapter; 
        this.ch_rep = ch_rep;
    }

    /**
     * Is this line generated by e.g. project extra work or via an order?
     * This has consequences as all amount- and amount related fields are disabled.
     */
    get isFixedGeneratedLine() {
        return !!this.id_project_extra_work || !!this.id_order_line;
    }
    /**
     * Is this line generated by project extra work
     */
    get isExtraWorkLine() {
        return !!this.id_project_extra_work;
    }
    /**
     * Is this line generated by an order
     */
    get isOrderLine() {
        return !!this.id_order_line;
    }

    /**
     * When our vat is shifted, clear the value.
     */
    unsetVatShifted() {
        if (vat.isShifted(this.id_vat)) {
            this.id_vat = null;
        }        
    }

    toJSON() {
        return {
            id_vat:                  this.id_vat,
            id:                      this.id,
            ihp_type:                this.ihp_type,
            ihp_amount:              this.ihp_amount,
            ihp_pd_name:             this.ihp_pd_name,
            ihp_sales_price:         this.ihp_sales_price,
            ihp_discount:            this.ihp_discount,
            id_product:              this.id_product,
            id_mandays_registration: this.id_mandays_registration,
            id_unity:                this.id_unity,
            id_project_extra_work:   this.id_project_extra_work,
            id_order_line:           this.id_order_line,
            id_project:              this.id_project,
            id_project_chapter:      this.id_project_chapter,
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
//
//
class clsSalesInvoice extends clsModel {

    id                      = null;
    _id_relation            = null;
    _id_project              = null;
    pro_name                = null;
    pro_number              = null;
    id_project_chapter = null; 
    ch_name = null; 
    ch_rep = null;

    id_person               = null;
    inv_number              = null;
    _inv_date                = null;
    inv_exp_date            = null;
    inv_book_date           = null;
    inv_name                = null;
    inv_rel_name            = null;
    inv_sub_total           = null;
    inv_vat_total           = null;
    inv_total               = null;
    inv_status              = null;
    inv_comment             = null;
    inv_reference           = null;
    _inv_use_g_account       = null;
    _inv_g_account_type      = null;
    _inv_g_account_perc      = null;
    _inv_wage                = null;
    _inv_g_amount            = null;
    _inv_vat_shifted         = null;
    inv_total_paid          = null;
    reminder1_date          = null;
    reminder2_date          = null;
    defaultnotice_date      = null;
    inv_lines_incl_excl_vat = null;
    use_payment_link        = null;

    lines                  = [];

    rel_g_account_perc_calculate_wage = 0; 

    get inv_use_g_account() {
        if (this.isCreditInvoice) {
            return false;
        }
        return this._inv_use_g_account;
    }
    set inv_use_g_account(v) {
        this._inv_use_g_account = v;
    }

    get inv_vat_shifted() {
        return this._inv_vat_shifted;
    }
    set inv_vat_shifted(value) {
        this._inv_vat_shifted = value;        
        if (this.isFilling) {
            return; // Done.
        }
        if (!value) {            
            this.lines.forEach( (line) => line.unsetVatShifted());
        }
    }

    get hasLines() {
        return this.lines.length > 0;
    }

    /**
     * Get the lines which are not text lines.
     */
    get productLines() {
        return this.lines.filter( (line) => line.ihp_type != "text");    
    }

    /**
     * Should an invoice by default be created incl. or excl vat?
     */
    get useDefaultInclExclVat() {
        // Is the feature enabled for this company?
        if (!feature.canConsumerPrice) {
            return "excl";
        }
        return settings.incl_excl_vat || "excl";
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Project stuff
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    get id_project() {
        return this._id_project;
    }
    set id_project(value) {
        this._id_project = value;

        if (this.isFilling) {
            return; // Done.
        }
        // Cascade the project change to the lines.
        this.productLines.forEach( (line) => {
            line.setProject(this.id_project, this.pro_number, this.id_project_chapter, this.ch_rep);
        })
    }

    /**
     * The project is not changable when one or more lines are generated from 
     * project extra work or via an order. 
     */
    get isFixedProject() {
        return !!this.productLines.find( (line) => !!line.isFixedGeneratedLine);
    }

    /**
     * Get the number of different projects selected in the lines.
     */
    get cntProjects() {
        var map = {};
        this.productLines.forEach( (line) => {
            if (line.id_project) {
                map[`p${line.id_project}`] = 1;
            }
        })
        return Object.keys(map).length;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // G-Account stuff
    // 1) No relation defaults. 
    //    Just use the values as the user types in the fields. 
    // 2) Relation defaults are present. 
    //    In this case, the field 'this.rel_g_account_perc_calculate_wage' is set to the wage percentage.
    // 
    //    Now: inv_wage returns: 
    //    1) When the user has NOT manually changed the wage amount: 
    //       --> percentage(inv_total,  this.rel_g_account_perc_calculate_wage)
    //    2) Otherwise, the _inv_wage value, which is the manual value.
    // 
    //  3) The relation has defaults but the user change one of the g-amount settings manually
    //     ==> When user changes manually: set  this.rel_g_account_perc_calculate_wage to 0
    //     ==> hereafter, always return the manually filled value.
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    get inv_g_account_type() { return this._inv_g_account_type }
    get inv_g_account_perc() { return this._inv_g_account_perc }
    set inv_g_account_type(value) { 
        this._inv_g_account_type = value;
        this.rel_g_account_perc_calculate_wage = 0; // Manual input. See comment above.
    }
    set inv_g_account_perc(value) { 
        this._inv_g_account_perc = value;
        this.rel_g_account_perc_calculate_wage = 0; // Manual input. See comment above.
    }
    set inv_wage(value) { 
        this._inv_wage = value;
        this.rel_g_account_perc_calculate_wage = 0; // Manual input. See comment above. 
    }
    set inv_g_amount(v) {
        this._inv_g_amount = v;
        this.rel_g_account_perc_calculate_wage = 0; // Manual input. See comment above.
    }
    get inv_wage() {
        // When relation default applies, use that.
        if (this.rel_g_account_perc_calculate_wage) {
            return percentage.calc(this.totalIncl, this.rel_g_account_perc_calculate_wage, 2);            
        }
        return this._inv_wage; // the stored value
    }
    get inv_g_amount() {
        // Fixed amount
        if (this.inv_g_account_type == 'amount') {
            return this._inv_g_amount;
        }
        // Otherwise, use either the relation default or the manually set amount.
        // No need to care here as it is calculated in the inv_wage getter.
        return percentage.calc(this.inv_wage, this.inv_g_account_perc, 2)
    }

    get noGAmount() {
        return Number(this.totalIncl) - Number(this.inv_g_amount);
    }
    
    get modelRep() {
        return this.inv_number || "Concept";
    }

    get statusRep() {
        if (this.isNew) {
            return 'Nieuw';
        }
        switch (string.lower(this.inv_status)) {

            case string.lower(Constants.invoice.status.NEW)      : return "Nieuw";
            case string.lower(Constants.invoice.status.CONCEPT)  : return "Concept";
            case string.lower(Constants.invoice.status.OPEN)     : 
                if (this.defaultnotice_date) {
                    return "Ingebrekestelling";
                }
                if (this.reminder2_date) {
                    return "Herinnering 2";
                }
                if (this.reminder1_date) {
                    return "Herinnering 1";
                }
                if (this.inv_total_paid) {
                    return "Deelbetaling";
                }
                if (this.inv_total_paid) {
                    return "Deelbetaling";
                }
                if (date.isInPast(this.inv_exp_date)) {
                    return "Vervallen";
                }
                return "Open";
            case string.lower(Constants.invoice.status.PAID)     : return "Betaald";
        }
        return "-";
    }

    get useAmountsExclVat() {
        return this.inv_lines_incl_excl_vat == "excl"
    }
    set useAmountsExclVat(value) {
        this.inv_lines_incl_excl_vat = value ? "excl" : "incl";
    }
    get useAmountsInclVat() {
        return this.inv_lines_incl_excl_vat == "incl"
    }    
    set useAmountsInclVat(value) {
        this.inv_lines_incl_excl_vat = value ? "incl" : "excl";
    }
    /**
     * When the status is open and a reminder is sent, reflect the correct substatus.  
     * 
     */
    get subStatus() {
        if (string.lower(this.inv_status) != "open") {
            return null;
        }
        let strSubStatus = null;
        if (this.reminder1_date) {
            strSubStatus = "reminder1";
        }
        if (this.reminder2_date) {
            strSubStatus = "reminder2";
        }
        if (this.defaultnotice_date) {
            strSubStatus = "defaultnotice";
        }
        if (this.inv_total_paid) {
            return "wrongly-paid";
        }
        if (date.isInPast(this.inv_exp_date)) {
            strSubStatus = "expired";
        }

        return strSubStatus;
    }

    /**
     * Calculate the total of the lines per vat type.
     * It is basicly just a sum of all lines with the same vat type.
     * 
     * Note that the lines use a field amount_excl. 
     * That field contains the line amount, which can be either be incl. or excl. vat.  
     * We return just the value.
     * 
     * @param {*} id_vat 
     * @returns 
     */
    calcLineTotalPerVatType(id_vat) {
        let total = 0;
        let lines = this.lines;
        if (id_vat) {
            lines = this.lines.filter( (line) => line.id_vat == id_vat); 
        }
        if (!lines || !lines.length) {
            return null;
        }
        lines.forEach( (line) => {
            total += Number(line.amount_excl);
        });
        return total;
    }

    get vatLines() {
        if (!this.lines || !this.lines.length) {
            return [];
        }
        if (this.inv_vat_shifted) {
            var total = 0;
            this.lines.forEach( (line) => {
                total += Number(line.amount_excl);
            });    
            return [
                {
                    name: 'BTW Verlegd',
                    lineAmountExclVat: total,
                    lineAmountInclVat: total,
                    vat: 0
                }
            ];
        }
        let result = [];
        let list = vatlist.list;
        list.forEach( (v) => {
            let vatLineTotal = this.calcLineTotalPerVatType(v.id);
            if (vatLineTotal !== null) {
                
                var lineAmountExclVat = 0;                
                var lineAmountInclVat = 0;
                var vatAmount = 0;
                
                if (this.useAmountsExclVat) {
                    lineAmountExclVat = vatLineTotal;
                    vatAmount = vat.excl2vat(v.id, vatLineTotal);    
                    lineAmountInclVat = vatAmount + vatLineTotal;
                } else {
                    lineAmountInclVat = vatLineTotal; // The line is incl. vat
                    lineAmountExclVat = vat.incl2excl(v.id, lineAmountInclVat);
                    vatAmount  = lineAmountInclVat - lineAmountExclVat; // vat.incl2vat(v.id, lineAmountInclVat);
                }
                result.push({name: v.name, lineAmountExclVat: numeric.round(lineAmountExclVat,2), vat: numeric.round(vatAmount,2), lineAmountInclVat: numeric.round(lineAmountInclVat,2)})
            }            
        })        
        return result;
    }

    get totalExcl() {
        let lines = this.vatLines;
        let total = 0;
        lines.forEach( (line) => {
            total += Number(line.lineAmountExclVat);
        })

        return total || 0;
    }

    get totalIncl() {
        let lines = this.vatLines;
        let total = 0;
        lines.forEach( (line) => {
            total += Number(line.lineAmountInclVat);
        })
        return total || 0;
    }
    get isCreditInvoice() { 
        return this.totalIncl < 0;
    }

    /**
     * From the provided project, propagate defaults to other fields. 
     * This is used when a project is selected by a client. When we are still in concept, 
     * project fields like relation and name are propagated to the matching invoice fields. 
     * 
     * Note: This method does not clear when null, empty or {id:null} is provided.
     *       
     */
    setProjectFields(objProject) {
        if (!objProject || !objProject.id) {
            return; 
        }
        // Even if this is already set, just do it        
        this.id_project = objProject.id;
        this.pro_number = objProject.pro_number;
        // When we are open or later, this is it. 
        // We do not take over any default from the project as we are not editable.
        if (this.isOpenOrLater) {
            return;
        }

        // Otherwise, set the reference name and relation. Relation will propagate further via the id_relation setter.
        this.inv_reference = objProject.pro_reference;
        this.inv_name = objProject.pro_name;
        this.id_relation = objProject.id_relation;
    }


    /**
     * set Relation defaults
     */
    setRelationDefaults() {
        this.setDateDefaults();        

        // clear siblings as we changed the relation
        this.id_person = null;        

        let rel = relation.one(this.id_relation);
        if (!rel) {
            return;
        }      

        // Set the invoice shifted setting
        this.inv_vat_shifted = !!rel.rel_vat_shifted;

        // Set the G account settings
        // We just set the properties from the relation and let the g-logic calulate the totals. 
        if (rel.rel_use_g_account) {

            // Use the internal properties so that the defaults are not reset. 
            this._inv_g_account_type = "percentage";   
            this._inv_g_account_perc = Number(rel.rel_g_account_perc);              
            // When defaults are set via the relation, the g amount is a percentage of the total invoice.
            // The relation is set when creating the invoice, therefor, the g-amount must be dynamicly calculated. 
            // This is done via the rel_g_account_perc_calculate_wage value. 
            this.rel_g_account_perc_calculate_wage = rel.rel_g_account_perc_calculate_wage;
            this.inv_use_g_account = true;
        } else {
            this.inv_use_g_account = false;
            this.inv_g_amount = 0;
        }

        let street = string.concat(" ", rel.adr_street, rel.adr_street_number);
        this.address = string.concat(", ", rel.adr_city, street);

        if (string.isEmpty(this.address)) {
            this.address = "(leeg adres)";
        }

        this.use_payment_link = !!rel.use_invoice_payment_link; 

        // Silently let the relation load so that the contactpersons are retrieved.
        relation.refresh(this.id_relation);
    }

    /**
     * Set date default based on the selected date and the relation settings.
     * 
     */
    setDateDefaults() {
        if (!this.inv_date) {
            this.inv_date = date.iso.today(); // We must have a date anyways.
        }
        // When filling the data, do not overwrite stored data.
        if (this.isFilling) {
            return;
        }
        let rel = relation.one(this.id_relation);
        if (!rel) {
            return;
        }              
        this.paymentdays = rel.rel_payment_terms_days || settings.payment_duedays || 30;
        this.inv_exp_date = date.fmt.iso(this.inv_date, this.paymentdays);
    }

    get inv_date() { return this._inv_date; }
    set inv_date(value) { 
        this._inv_date = value;
        this.setDateDefaults();
    }
    
    /**
     *  get/set the relation field and propagate defaults for the relation to appropriate fields.
     */
    get id_relation() { return this._id_relation; }
    set id_relation(value) {        
        this._id_relation = value;
        if (this.isFilling) {
            // If a relation is set, make sure its sibblings are loaded.
            // This is unrelated to whether this is a fill or a change action.
            if (value) {
                // No need to execute this async.
                relation.refresh(this.id_relation)
            }
            return;
        }
        // Set the default expiry date.
        this.setRelationDefaults();
    }

    /**
     * The currently selected relation address
     */    
    get rel_address() {
        let rel = relation.one(this.id_relation);
        if (!rel) {
            return "";
        }      
        let street = string.concat(" ", rel.adr_street, rel.adr_street_number);
        let address = string.concat(", ", rel.adr_city, street);        
        if (string.isEmpty(address)) {
            return "(adres is leeg)";
        }
        return address;
    }

    get rel_name() {
        let rel = relation.one(this.id_relation);
        if (!rel) {
            return '-';            
        }         
        return rel.rel_name;
    }

    /**
     * Download the document.
     * @returns 
     */
    async downloadPdf
    () {
        if (!this.id) {
            throw new "De factuur moet eerst worden opgeslagen.";
        }
        return api.getPdf(this.id);  
    }
    get downloadTitle() {
        if (this.inv_number) {
            return `Factuur ${this.inv_number}`;
        }
        return 'Factuurvoorbeeld';
    }

    /**
     * The default api load implementation just loads the data via the api with the id.
     * Override this method for example to load the data via other parameters (e.g. id_employee, id_tariff). 
     */
    apiLoad(id, params, extraData) {
        params = params ||{};
        extraData = extraData ||{};
        if (extraData.action == ACTION_CREDIT) {
            return this.api.credit(id, params);
        }
        if (extraData.action == ACTION_COPY) {
            return this.api.copy(id, params);
        }
        return super.apiLoad(id, params);
    }
    
    /**
     * Set the status back to concept
     * @param {} data 
     */
    async backToConcept() {
        let result = null;
        this.isDataLoading = true;
        try {
            result = await api.backToConcept(this.id);
            this.fill(result.data);
            this.sendSavedEvent();
            this.sendStatsRefreshEvent();   
        } finally {
            this.isDataLoading = false;
        }
        return result;
    }
        
    /**
     * The backend warnings are issued before sending, not when saving.
     * This is because the invoice can be built up during multiple edit sessions. 
     * No need to bore the user with the same warnings over and over.
     * 
     * Frontend warnings: 
     *   - when at least one line has a project filled, warn when not all lines have 
     *     projects linked.
     * 
     * @param {} data 
     */
    async checkBeforeSave(data) {
        var lineWithProject = this.productLines.find( (line) => !!line.id_project);
        if (!lineWithProject) {
            return; // Fine. No project used.
        }
        var lineWithoutProject = this.productLines.find( (line) => !line.id_project);
        if (lineWithoutProject) {
            await noty.confirm("U heeft niet alle factuurregels aan een project gekoppeld. Wilt u doorgaan?.");
        }
    }

    /**
     * Create a new line and, in addition to the provided data, apply required defaults. 
     * Most important is that the project is defaulted.
     * 
     * USE THIS METHOD INSTEAD OF creating instances of clsInvoiceLine !
     * 
     * Note that the defaults are only applied after data is filled.
     */
    _createNewLine(data) {
        if (!this.isFilling) {
            if (this.id_project) {
                if (!data.id_project) {
                    data.id_project = this.id_project;
                    data.pro_number = this.pro_number;
                    // When id_chapter is in the data, do not overwrite the chapter. Also, 
                    // when the id_project_chapter in the data is undefined, the main project is chosen. Do not change chapter in this case.
                    if (!data.id_project_chapter && (undefined !== data.id_project_chapter)) {
                        data.id_project_chapter = this.id_project_chapter;
                        data.ch_rep = this.ch_rep;    
                    }
                }
            }
        }
        return  new clsInvoiceLine(data);
    }
    /**
     * Add a line to the vat list
     * @param {*} line 
     */
    addLine(data, index) {
        data = data || {}
        let line = this._createNewLine(data);
        if (index !== undefined && index >=0) {
            this.lines.splice(index, 0, line);
        } else {
            this.lines.push(line);
            index = this.lines.length -1;
        }
        return index;
    }

    addLineAfter(item, data) {
        let ix = this.lines.indexOf(item);
        if (ix >=0) {ix++}
        this.addLine(data, ix)        
    }
    addLineBefore(item, data) {
        this.addLine(data, this.lines.indexOf(item));
    }
    addTextLineAfter(item) {
        this.addLineAfter(item, {ihp_type: "text"});
    }
    addTextLineBefore(item) {
        this.addLine({ihp_type: "text"}, this.lines.indexOf(item));
    }

    /**
     * Remove a line
     * @param {} line 
     */
    removeLine(removeline) {
        this.lines = this.lines.filter( (line) => line != removeline);
    }

    async expandRecipe(recipeLine) {
        this.isDataLoading = true;
        try {
            var amount = recipeLine.ihp_amount; 
            var id_recipe = recipeLine.id_product;
            var result = await apiRecipe.expand(id_recipe, amount);
            var lines = result.data ||[];
            lines.forEach ( (line) => {
                line.id = line.id_product;
                this.addProductLine(line, recipeLine)
            })
            this.removeLine(recipeLine);
//            console.error(result);
//            this.fill(result.data);                
        } finally {
            this.isDataLoading = false;
        }  
    }

    // Refresh the line with the (updated) details of the given product.    
    refreshProductLine(line, product) {
        if (!product) {
            return; // Just skip.
        }
        var ihp_sales_price = product.pd_sales_price_excl_vat;
        if (!this.useAmountsExclVat) {
            ihp_sales_price = product.pd_sales_price_incl_vat;
        }
        
        line.ihp_pd_name =      product.pd_name;
        line.pd_code =          product.pd_code;
        line.pd_supplier_code = product.pd_supplier_code;
        line.pd_supplier_name = product.pd_supplier_name;
        line.ihp_sales_price =  ihp_sales_price;
        line.id_vat =           product.id_vat || line.id_vat;
        line.id_unity =         product.id_unity || line.id_unity;
        line.sup_name =         product.sup_name;

    }
    /**
     * Add a product line to the list.
     * @param {} product 
     * @returns 
     */
    addProductLine(product, afterLine) {
        var ix = -1;
        if (afterLine) {
            ix = this.lines.indexOf(afterLine);
            if (ix >=0) {ix++}
        }

        if (!product) {
            return;
        }
        var ihp_sales_price = product.pd_sales_price_excl_vat;
        if (!this.useAmountsExclVat) {
            ihp_sales_price = product.pd_sales_price_incl_vat;
        }
        let line = {
            id_product: product.id,
            ihp_pd_name:      product.pd_name,
            pd_code:          product.pd_code,
            pd_supplier_code: product.pd_supplier_code,
            pd_supplier_name: product.pd_supplier_name,
            ihp_sales_price:  ihp_sales_price,
            id_vat:           product.id_vat,
            id_unity:         product.id_unity,
            sup_name:         product.sup_name,
        }
        if (product.amount) {
            line.ihp_amount = product.amount;
        }
        // If lines are already present, and a project is used, copy it.
        if (this.lines.lastLine && this.detaillines.lastLine.id_project) {
            line.id_project = this.detaillines.lastLine.id_project;
            line.pro_name   = this.detaillines.lastLine.pro_name;
            line.pro_number   = this.detaillines.lastLine.pro_number;
        } 
        // console.log('Adding: ', product, line)
        return this.addLine(line, ix >=0 ? ix : undefined);
    }

    /**
     * Add a text line to the list.
     * @param {} product 
     * @returns 
     */
    addTextLine(text) {
        text = text ||{text: null}
        let line = {
            ihp_type:       "text",
            ihp_pd_name:    text.text,
        }

        // console.log('Adding: ', text, line)
        return this.addLine(line);
    }

    /**
     * Add a 'manday' line as a line to the invoice.
     * @param {} manday
     * @returns 
     */
    addMandayLine(manday) {
        if (!manday) {
            return;
        }
        var id_mandays_registration = manday.id;
        var mdr_number = manday.mdr_number;
        var ihp_pd_name = manday.mdr_title;
    
        let line = {
            ihp_pd_name:      ihp_pd_name,
            id_mandays_registration:  id_mandays_registration,
            mdr_number:               mdr_number
        }

        // If lines are already present, and a project is used, copy it.
        if (this.lines.lastLine && this.detaillines.lastLine.id_project) {
            line.id_project = this.detaillines.lastLine.id_project;
            line.pro_name   = this.detaillines.lastLine.pro_name;
            line.pro_number   = this.detaillines.lastLine.pro_number;
        } 
        // console.log('Adding: ', product, line)
        return this.addLine(line);
    }
    /*
     * Add a 'project' line as a line to the invoice.
     * @param {} project
     * @returns 
     */
    addProjectLine(project) {
        if (!project) {
            return;
        }
    
        let line = {
            ihp_pd_name: project.pro_name,
            id_project : project.id,
            pro_name   : project.pro_name,
            pro_number : project.pro_number,
            id_project_chapter : project.id_project_chapter,
            ch_rep : project.ch_rep,


        }
//        if (!this.id_project) {
//            this.id_project = project.id;
//            this.pro_name   = project.pro_name;
//            this.pro_number = project.pro_number;
//        }
//        console.log('Adding line: ', line)
        return this.addLine(line);
    }

    /**
     * Is the invoice open (or paid)
     */
    get isOpenOrLater() {

        switch (string.lower(this.inv_status)) {
            case string.lower(Constants.invoice.status.NEW)      : 
            case string.lower(Constants.invoice.status.CONCEPT)  : 
                return false;
        }
        
        return true;
    }
    /**
     * Is the invoice in status concept?
     */
    get isConcept() {
        return string.compare(this.inv_status, Constants.invoice.status.CONCEPT); 
    }

    /**
     * Is the invoice sent to accountancy?
     */
    get isSentToAccountancy() {
        return false; // TODO
    }

    /**
     * When a new invoice is saved and hence is now a concept invoice, send a stats update event
     * Note that this hook is called before the data is re-populated.
     */
    onAfterSave(resultData) {
        if (this.isNew) {
            this.sendStatsRefreshEvent();   
        }
    }

    /**
     * Create a copy of this invoice
     */
    async createCopy() {
        this.isDataLoading = true;
        try {
            var result = await api.copy(this.id);
            this.fill(result.data);                
        } finally {
            this.isDataLoading = false;
        }  
    }

    /**
     * Remove this invoice
     */
    async removeInvoice() {
        this.isDataLoading = true;
        try {
            var result = await api.remove([this.id]);
            eventbus.model.removed(modelName, [this.id]);
        } finally {
            this.isDataLoading = false;
        }  
    }
    
    /**
     * Create a credit invoice for this invoice
     */
    async createCredit() {
        this.isDataLoading = true;
        try {
            var result = await api.credit(this.id);
            this.fill(result.data);                
        } finally {
            this.isDataLoading = false;
        }  
    }

    /**
     * Make sure that the vendors array is always present, frontend probably depends on array type. 
     * @param {} data 
     * @returns 
     */
    fill(data) {
        data = data ||{};
        data.inv_lines_incl_excl_vat = data.inv_lines_incl_excl_vat || this.useDefaultInclExclVat;    
        data.inv_vat_shifted         = data.inv_vat_shifted || false;
        data.inv_use_g_account       = data.inv_use_g_account || false; 
        data.inv_g_account_type      = data.inv_g_account_type || "amount";
        data.inv_g_account_perc      = data.inv_g_account_perc || 0; 
        data.inv_status              = data.inv_status || Constants.invoice.status.NEW;
        data.inv_date                = data.inv_date || date.iso.today(); 
        data.inv_g_account_perc      = data.inv_g_account_perc || 0;
        data.inv_wage                = data.inv_wage || 0;
        data.inv_g_amount            = data.inv_g_amount || 0;
        data.use_payment_link        = !!data.use_payment_link;
        
        this.inv_sub_total = data.inv_sub_total;
        this.inv_vat_total = data.inv_vat_total;
        this.inv_total = data.inv_total;

        // Default the relation g-amount setting to 0 as it must not be used for existing invoices. 
        this.rel_g_account_perc_calculate_wage = 0;
        let result = super.fill(data);

        // Important, we are still filling the data from the backend. 
        // This means we still do not want to trigger setting defaults based on field changes.
        // Therefore, we - like the parent implementation - must set the isFilling property.
        try {
            this.isFilling = true;

            let lines = [];
            (data.lines||[]).forEach( (line) => {
                lines.push(this._createNewLine(line));
            })
            this.lines = lines;
        }
        finally {
            this.isFilling = false;
        }
        return result;
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: mandatoryFields,
          id_optimit_type: id_optimit_type, 
          fillable: fields
        })
    } 

 }
 export default fnCreate(clsSalesInvoice , 'clsSalesInvoice');

import string from '@lib/string'
import http from '@app/http'
import pdfErrorDoc from './pdfErrorDoc'

// For a couple of reasons, we should use the latest pdf.js package. 
// The most obvious reasons: 
//  - faster 
//   - by default, without messing around, it uses a worker thread, which makes appearance much more fluent.  
// An update to webpack5 is required to use later pdfjs packages. 
// Todo so: 
// vue upgrade @vue/cli-service  
// vue upgrade @vue/cli-plugin-eslint 
// vue upgrade @vue/cli-plugin-babel

import * as pdfjsLib from 'pdfjs-dist/webpack.mjs';
import * as pdfjsViewer from 'pdfjs-dist/web/pdf_viewer.mjs'

//
// Usage:
// import clsPdfjs from '@lib/clsPdfjs'
// var obj = new clsPdfjs();
// // onMounted: 
//  obj.create(element); 
//
/////////////////////////////////////////
//
// obj.loadData(pdfData)
// obj.zoomIn();

var CMAP_URL = "/dist/pdfjs-dist/cmaps/";
var CMAP_PACKED = true;
const TEXT_LAYER_MODE = 0; // DISABLE

const emptyDocument = string.base64Decode("JVBERi0xLjEKJeLjz9MKMSAwIG9iaiAKPDwKL1BhZ2VzIDIgMCBSCi9UeXBlIC9DYXRhbG9nCj4+CmVuZG9iaiAKMiAwIG9iaiAKPDwKL01lZGlhQm94IFswIDAgNTk1IDg0Ml0KL0tpZHMgWzMgMCBSXQovQ291bnQgMQovVHlwZSAvUGFnZXMKPj4KZW5kb2JqIAozIDAgb2JqIAo8PAovUGFyZW50IDIgMCBSCi9NZWRpYUJveCBbMCAwIDU5NSA4NDJdCi9UeXBlIC9QYWdlCj4+CmVuZG9iaiB4cmVmCjAgNAowMDAwMDAwMDAwIDY1NTM1IGYgCjAwMDAwMDAwMTUgMDAwMDAgbiAKMDAwMDAwMDA2NiAwMDAwMCBuIAowMDAwMDAwMTQ5IDAwMDAwIG4gCnRyYWlsZXIKCjw8Ci9Sb290IDEgMCBSCi9TaXplIDQKPj4Kc3RhcnR4cmVmCjIyMQolJUVPRgo=");

/**
 * For reference, see: 
 * https://github.com/mozilla/pdf.js/blob/master/examples/mobile-viewer/viewer.mjs
 * 
 * Usage: 
 * var pdfjs = new clsPdfjs();
 * document.addEventListener("DOMContentLoaded",
 *    function () {
 *        var container = document.getElementById("viewerContainer");
 *        pdfjs.create(container)
 *   },
 *   true
 * );
 */

class clsPdfjs {
    pdfLoadingTask = null;
    pdfDocument = null;
    pdfViewer = null;
    pdfLinkService = null;
    eventBus = null;
    isCreated = false;
    fnCallbackOnLoading = null;
    _isLoading = false;
    constructor(options) {
        options = options ||{}
        this.fnCallbackOnLoading = options.callbackOnLoading;
    }
    get isLoading() { return this._isLoading;}
    set isLoading(value) { 
        this._isLoading = !!value; 
        if (this.fnCallbackOnLoading) {
            this.fnCallbackOnLoading(this.isLoading);
        }
    }
    
    /**
     * Closes opened PDF document.
     * @returns {Promise} - Returns the promise, which is resolved when all
     *                      destruction is completed.
     */
    async close() {

        if (!this.pdfLoadingTask) {
            return;
        }

        await this.pdfLoadingTask.destroy();
        this.pdfLoadingTask = null;

        if (this.pdfDocument) {
            this.pdfDocument = null;

            this.pdfViewer.setDocument(null);
        }
    }
    async empty() {
        this._loadData(emptyDocument);
    }
    async loadErrorData() {
        let data = pdfErrorDoc.data();
        return data;
    }

    /**
     * When the parameter is a function, wait for it to be retrieved and return it.
     * Otherwise, return the data.
     * 
     * @param {*} data 
     * @returns 
     */
    async _resolveData(data) {
        if (typeof data == 'function') {
            try {
                data = await data();
            }
            catch (e) {
                data = await this.loadErrorData();
            }
        }

        // Yes. I know. The data can be wrapped in multiple data levels because of the http call.
        return data?.data?.data || data?.data || data || null;
    }

    async loadData(data, filename /* optional*/) {
        try {
            this.isLoading = true;            
            return await this._loadData(data, filename);
        }
        finally {
            this.isLoading = false;
        }
    }
    // 
    // Load base64 data directly.
    // Note: do not load an empty document via this method as it will assume error and render an 
    //       error document. 
    // Note: the base64 data is supposed to come from a server. 
    //       As such, we assume that the data is base64 encoded on the server. 
    //       For this, we can not use standard atob for decoding back to binary as 
    //       conversion will fail - something with coding points. 
    //       Therefore, we decode it to an array buffer, which works fine with server 
    //       generated base64.
    async loadBase64Data(data, filename /* optional*/) {
        var decodedData = null;
        if (data) {
            decodedData = await string.base64DecodeToArrayBuffer(data);
        }
        if (!decodedData) {
            decodedData = await this.loadErrorData();
        } 
        // We use _loadData directly as we don't need a loading indicator as the data is there already.
        return await this._loadData(decodedData, filename);
    }

    async _loadData(data, filename /* optional*/) {
        data = await this._resolveData(data);
        // When no data is provided, show an empty document.
        if (!data) {
            return this.empty();
        }

        this.currentFileName = filename || "";        
        this.downloadComplete = false;
        // Feed the data as is in the pdf object
        this.pdfLoadingTask = pdfjsLib.getDocument({
            data:data,
            cMapUrl: CMAP_URL,
            cMapPacked: CMAP_PACKED,
        });

        var self = this;
        return this.pdfLoadingTask.promise.then(
            // Success
            function (pdfDocument) {
                self.pdfDocument = pdfDocument;
                self.pdfViewer.setDocument(pdfDocument);
                self.downloadComplete = true;
            }, 
            // Error
            function (e) {
                var pdfVersion = `PDF.js v${pdfjsLib.version || "?"} (build: ${pdfjsLib.build || "?"})`;
                console.error(e, pdfVersion);
                self.downloadComplete = true;
            }            
        );
    }

    create(element) {
        if (this.isCreated) {
            this.empty();
            return;
        }

        this.eventBus = new pdfjsViewer.EventBus();
        this.pdfViewer = new pdfjsViewer.PDFViewer({
            container:      element,
            eventBus:       this.eventBus,
            annotationMode:       pdfjsLib.AnnotationMode.DISABLE,
            // Note that when the annotationEdtitorMode is not disabled, loading another 
            // document in the same instance results in a runtime error. 
            // As we do not use annotations this seems not problematic but can be researched when
            // an update of pdjs_dist is installed. 
            //  
            annotationEditorMode: pdfjsLib.AnnotationEditorType.DISABLE,
            annotationEditorType: pdfjsLib.AnnotationEditorType.NONE,
            textLayerMode:  1, // TEXT_LAYER_MODE,
        });
        var self = this;
        this.eventBus.on("pagesinit", function () {
            // We can use pdfViewer now, e.g. let's change default scale.
            self.pdfViewer.currentScaleValue = "page-width";    
            self.pdfViewer.pagesRotation = 0;
        });        
        this.isCreated = true;
    }

    zoomIn() {
        if (this.pdfViewer.currentScale < 2) {
            this.pdfViewer.currentScale += 0.1;
        }
    }
    zoomOut() {
        if (this.pdfViewer.currentScale > 0.5) {
            this.pdfViewer.currentScale -= 0.1;
        }
    }
    maximize() {
        this.pdfViewer.currentScaleValue = "page-width";
    }
    crop() {
        this.pdfViewer.currentScaleValue = "page-fit";
    }
    rotateLeft() {
        this.pdfViewer.pagesRotation = this.pdfViewer.pagesRotation - 90;
    };
    rotateRight() {
        this.pdfViewer.pagesRotation = this.pdfViewer.pagesRotation + 90;
    };
    download(filename) {
        if (!this.pdfDocument) {
            console.log('no pdf document')
            return;
        }
        if (!this.downloadComplete) {
            console.log('not downloadcomplete')
            return;
        }
        var name = string.coalesce(filename, this.currentFileName, 'document.pdf');
        this.pdfDocument.getData().then(
            function getDataSuccess(data) {
                http.downloadRawDataToClient(data, 'application/pdf', name);
            }, () => {}
        );
    };

}



export default clsPdfjs;
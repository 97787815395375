import http from '@app/http'
import Constants from '@app/consts'
import eventbus from '@app/eventbus'
import string from '@lib/string'

/**    

 * Base class for Api's
 */
class clsApi {

    // E.g. /admin/emails
    baseUrl=null;

    /**
     * Constructor
     * @param {} baseUrl 
     */
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }
    
    /**
     * in: 1      out: [1]
     * in: [1,2]  out: [1,2]
     * 
     * @param {*} ids 
     * @returns 
     */
    ids(ids) {
        return ids && Array.isArray(ids) ? ids : [ids];
    }

    /**
     * Get the hose we will talk to. 
     * By default, current host. Override to specify another backend.
     */
    get host() {
        return ''; // By default, post to same domain as we are on.
    }

    /**
     * Get the basis for the url.
     */
    get url() {
        return `${this.host}${this.baseUrl}`;
    }

    /**
     * Post to the given url with the given options
     * Implemented here so that  a derived class needs not to decide about the http instance.
     * 
     * @param {*} url 
     * @param {*} options 
     * @returns 
     */
    post(url, params) {
        return http.post(`${this.url}/${url}`, params);        
    }

    /**
     * Put to the given url with the given options
     * Implemented here so that a derived class needs not to decide about the http instance.
     * 
     * @param {*} url 
     * @param {*} options 
     * @returns 
     */
    put(url, params) {
        return http.put(`${this.url}/${url}`, params);        
    }

    /**
     * Post to the given url with the given options
     * Implemented here so that  a derived class needs not to decide about the http instance.
     * 
     * @param {*} url 
     * @param {*} options 
     * @returns 
     */
    postPDF(url, options) {
        return http.postPDF(`${this.url}/${url}`, options);        
    }

    
    /**
     * get from the given url
     * Implemented here so that a derived class needs not to decide about the http instance.
     * 
     * Example: the data api is bound to url '/data'  
     * Now, data must be loaded from a specific endpoint 'loadsetting/[name]'. Say, 'loadsettings/manday'
     * 
     * The api can just call this.get('loadsettings/manday');
     * 
     * @param {*} url 
     * @param {*} options 
     * @returns 
     */
    async get(url, params) {
        return http.get(`${this.url}/${url}`, params);        
    }

    /** 
     * Download an item via an url.
     * By default, the [baseurl]/download/id format is used. 
     * To override it, specify a url for the id. 
     * For example: .download('download/attachment/1234')
    */
    async download(id_or_url, name) {        
        let endpoint = `${this.url}/download/${id_or_url}`;

        if (string.contains(`${id_or_url}`, "/")) {
            endpoint = `${this.url}/${id_or_url}`;
        }
        return http.download(endpoint, name);
    }

    // 
    // Example: 
    // --> 
    // let formData = new formData();
    // 
    // formData.append(`file1`, file);
    // formData.append("id", this.id);
    // formData.append("entity", this.entity);      
    // upload(formData);
    upload(formData, url, onProgress) {
        var url = url || 'upload';
        return http.upload(`${this.url}/${url}`, formData, onProgress);    
    }

    // 
    // Upload a file list. This works in association with e.g. the FileDrop component: 
    // --> <FileDrop @filesSelected="(a,b,c,d) => dlg.onFilesSelected(a,b,c,d)">

    // let formData = new formData();
    // 
    // formData.append(`file1`, file);
    // formData.append("id", this.id);
    // formData.append("entity", this.entity);      
    // upload(formData);
    async uploadFileList(fileList, options, onProgress) {
        options = options || {}
        var url = options.url || 'upload';
        var maxFiles = options.maxFiles||4;

        if (!fileList || !fileList.length) {
            return; // Just ignore.
        }

        let cnt = fileList.length;
        if (cnt > maxFiles) {
            eventbus.dialog.alert({
                title: "Te veel bestanden",
                body:  `U kunt maximaal ${maxFiles} bestand${(maxFiles == 1) ? '':'en'} tegelijk uploaden.` 
            })
            return;
        }
        let formData = new FormData();
        for (var n = 0; n < fileList.length; n++) {
            let file = fileList[n];
            if (file.size > Constants.limits.file_upload.bytes) {
                eventbus.dialog.alert({
                    title: "Te groot bestand",
                    body:  `De maximale bestandsgrootte is ${Constants.limits.file_upload.text}.`});
                return;
            }
            formData.append(`file${n}`, file);
        }
        formData.append("id_entity", this.id_entity);
        formData.append("id_optimit_type", this.id_optimit_type);

        return this.upload(formData, url);    
    }

    /**
     * A couple of predefined methods which many api's use.
     * @param {*} paging 
     * @param {*} filter 
     * @returns 
     */
    page(paging, filter, parent) {
        filter = filter ||{}
        if (parent) {
            for (var key in parent) {
                filter[key] = parent[key];
            }
        }
        return this.post(`page`, {paging: paging, filter: filter});
    }
    pageSingle(ids, parent) {
        var payload = {ids: this.ids(ids)}; 
        if (parent) {
            for (var key in parent) {
                payload[key] = parent[key];
            }
        }
        return this.post(`page`, {paging: {}, filter: payload});
    }
    load(id) { 
        return this.get(`load/${id}`); 
    }
    // load by 2 keys. A bit simplistic but all we need.
    load2(id1,id2) { 
        return this.get(`load/${id1}/${id2}`); 
    }
    // load default data for new.
    loadNew() { 
        return this.get(`loadnew`); 
    }
    copy(id) { 
        return this.get(`copy/${id}`); 
    }
    list() { 
        return this.get(`list`);
    }
    save(model) { 
        return this.post(`save`, model); 
    }
    // create is sparely used where creation and modification are handled differently.
    create(model) { 
        return this.post(`create`, model); 
    }
    remove(ids, parent) {
        var payload = {ids: this.ids(ids)}; 
        if (parent) {
            for (var key in parent) {
                payload[key] = parent[key];
            }
        }

        return this.post(`remove`, payload); 
    }
    removeOne(id, parent) {
        var payload = {id: id}; 
        if (parent) {
            for (var key in parent) {
                payload[key] = parent[key];
            }
        }

        return this.post(`remove`, payload); 
    }
    unRemove(ids, parent) { 
        var payload = {ids: this.ids(ids)}; 
        if (parent) {
            for (var key in parent) {
                payload[key] = parent[key];
            }
        }
        return this.post(`unremove`, payload); 
    }
    async archive(ids, parent) { 
        var payload = {ids: this.ids(ids)}; 
        if (parent) {
            for (var key in parent) {
                payload[key] = parent[key];
            }
        }

        return this.post(`archive`, payload); 
    }
    async unArchive(ids, parent) { 
        var payload = {ids: this.ids(ids)}; 
        if (parent) {
            for (var key in parent) {
                payload[key] = parent[key];
            }
        }
        return this.post(`unarchive`, payload); 
    }

    getWarnings(data, endpoint) {
        endpoint = endpoint || 'getwarnings';
        return this.post(endpoint, data); 
    }

    /**
     * Download data via the specified url. 
     * Typical use case: download a PDF for e.g. purchase invoice. 
     * The optional hideError can be used to let the caller handle the error instead of 
     * showing a generic error message in a dialog. 
     * In case of e.g. a PDF viewer, the error can be better shown in a custom PDF document.
     * @param {*} url 
     * @param {*} hideError 
     * @returns 
     */
    downloadData(url, hideError /* optional */) {        
        return http.downloadData(`${this.url}/${url}`, hideError);        
    }

    /**
     * Retrieve raw data (non-Json data) via a GET request
     * 
     * Returns a promise which returns: {
     *      data: [],                           // byte array
     *      contentType: 'application/pdf'
     *      filename: 'invoice.pdf'
     * }
     * 
     * @param {*} url     - the url
     * @param {*} params  - parameters which will be passed to the query string
     * @param {*} options - extra options for Axios &| hideError 
     * @returns 
     */
    getRaw(url, params, options) {    
        return http.getRaw(`${this.url}/${url}`, params, options);
    }
    /**
     * Retrieve raw data (non-Json data) via a POST request
     * 
     * Returns a promise which returns: {
     *      data: [],                           // byte array
     *      contentType: 'application/pdf'
     *      filename: 'invoice.pdf'
     * }
     * 
     * @param {*} url     - the url
     * @param {*} params  - parameters provided in the post body
     * @param {*} options - extra options for Axios &| hideError 
     * @returns 
     */
    postRaw(url, params, options) {    
        return http.postRaw(`${this.url}/${url}`, params, options);
    }

    /**
     * Retrieve raw data (non-Json data) via a GET request and download the result to the client.
     * 
     * Returns a promise which returns: {
     *      data: [],                           // byte array
     *      contentType: 'application/pdf'
     *      filename: 'invoice.pdf'
     * }
     * 
     * @param {*} url     - the url
     * @param {*} params  - parameters which will be passed to the query string
     * @param {*} options - extra options for Axios &| hideError 
     * @param {*} filename - optional filename. Will be deduced from server result when not provided.
     * @returns 
     */
    downloadGetRaw(url, params, options, filename) {    
        return http.downloadGetRaw(`${this.url}/${url}`, params, options, filename);
    }

    /**
     * Retrieve raw data (non-Json data) via a POST request and download the result to the client.
     *
     * Returns a promise which returns: {
     *      data: [],                           // byte array
     *      contentType: 'application/pdf'
     *      filename: 'invoice.pdf'
     * }
     * 
     * @param {*} url     - the url
     * @param {*} params  - parameters which will be passed to the query string
     * @param {*} options - extra options for Axios &| hideError 
     * @param {*} filename - optional filename. Will be deduced from server result when not provided.
     * @returns 
     */
    downloadPostRaw(url, params, options, filename) {    
        return http.downloadPostRaw(`${this.url}/${url}`, params, options, filename);
    }
    
    
}

export default clsApi;
//
// Usage: 
//  import routing from '@app/routing'
//

import clsRouting from '@/app/clsRouting'
import {ref} from 'vue'

let obj =  ref (new clsRouting());

export default obj.value;
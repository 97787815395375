import clsFeature from '@/app/clsFeature'

// Usage: 
// import feature from '@app/features';
// if (feature.canProject) {
//     console.log('Feature enabled')   
// }
// 
let feature = new clsFeature();

export default feature
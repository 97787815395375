import clsApi from '@cls/clsApi'
import http from '@app/http'

/**    
 * This class is identical to clsApi except that where a get request is executed 
 * in the base class, this class changes it to a post request. 
 * 
 * Reason: CORS caching.
 * Without caching, all requests are preceded with a CORS preflight request. 
 * With caching, the CORS option request is executed once per hour (depending on the browser).
 * 
 * We enabled caching, and it reduces the network cost with 40%. 
 * The problem is that if we use get requests to load the data, the url looks like: 
 *     https:/...../api/.../load/12345 
 * 
 * So, the urls for getting two relations are different and hence, caching does not work.
 * We are slowly migrating to a post request for every get. No need to rush.
 * For endpoints which support post, we can use this class now. 
 *      
 */
class clsApiPost extends clsApi {

    loadStats() {
        return this.post(`stats`); 
    }

    load(id) { 
        return this.post(`load`, {id: id}); 
    }
    // load by 2 keys. A bit simplistic but all we need.
    load2(id1,id2) { 
        throw 'load2 is not yet implemented for post.';
    }
    // load default data for new.
    loadNew() { 
        return this.post(`loadnew`); 
    }
    copy(id) { 
        return this.post(`copy`, {id: id}); 
    }
    list() { 
        return this.post(`list`);
    }

}

export default clsApiPost;
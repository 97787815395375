//
// Usage: 
//  import url from '@shared/lib/url'
//  token = url.getParam('token');
//

var url = {};

url.redirect = (link, reload) => {
    window.location.assign(link);
    if (reload) {
        window.location.reload();
    }
}
/**
 * Use this to open a link in a new tab.
 */
url.open = (link, target) => {
    target = target |"_blank";
    window.open(link, target);
}

url.getParam = (name) => {
    const queryString = window.location.search;    
    const urlParams = new URLSearchParams(queryString);    
    const value = urlParams.get(name);
    
    if (!value) {
        return null;
    }
    return value;
}
url.back = () => {
    history.back();
}

/**
 * Does the current url contain the given phrase?
 * Note that as we use hashes to navigate, we search the hash.
 */
url.contains = (str) => {
    return (window.location.hash     ||"").indexOf(str) >=0; 
}

var _getPathPart = function(name) {
    var q = `\/${name}\/([0-9]*)\/`
    var c = RegExp(q).exec(window.location.pathname ||"")     
    if (c && Array.isArray(c) && c.length == 2) {
        return c[1];
    }
    return null;
}
/**
 * Example: 
 *  url: 
 *      https://kantoor.optimit.nl/c/1234/#/relaties
 * 
 *  getPathPart("c") => 1234
 *  getPathPart("x") => null
 * 
 *  Note: this method only returns numeric values.
 * 
 * @param {*} name 
 */
url.getPathPart = (name) => {
    return _getPathPart(name);
}

// The url of optimit is start.optimit.nl 
// When not logged in, a redirect to start.optimit.nl/#/login is executed.
// Now a problem is that not all browsers handle no-caching uniform. 
// Allthough we set all the headers correctly and add metatags, the browser does just not 
// reload always. This is problematic as users most of times have no clue of pressing 'F5'
// when something strange happens. 
// This method is to cater for this. 
// 
// The backend returns a version header in all its calls. 
// When a user opens optimit, the apiVersion is via the first calls available in window.apiVersion. 
// The mechanism is now simple. 
// We compare the version in the path of the url (NOT THE HASH, BUT THE PATH) to the stored apiVersion.
// When the versions do not match, just redirect to the root url with the new hash. 
// 
// This particular method just redirects to the versioned url. 
// Skips when the version is already in the url.
// 
// Scenario 1. No version
//      current url: start.optimit.nl/#login  
//      apiVersion : 412061102
//      Redirect to: start.optimit.nl/v/412061102/#login  
//
// Scenario 2. An old version is in place
//      current url: start.optimit.nl/v/412061102/#/dashboard  
//      apiVersion: 412070842
//      Redirect to: start.optimit.nl/v/412070842/#/dashboard
//   
// Scenario 3. The versions match 
//      current url: start.optimit.nl/v/412061102/#/dashboard  
//      apiVersion: 412061102
//      - do not redirect
// 
// 
// 
url.redirectToVersionedUrl = function() {
    if (!window.apiVersion) {
        return false;
    }    
    // This is duplication from 
    var urlVersion = _getPathPart('v');

    // Scenario 3: - already using correct version
    if (urlVersion == window.apiVersion) {
        return false;
    }
    // Scenario 2 - no version in the url
    if (!urlVersion) {
        var url = window.location.toString().replace(location.host, `${location.host}/v/${window.apiVersion}`);
        window.location.assign(url);
        return true;
    }
    // Scenario 3 - old version in the url
    var url = window.location.toString().replace(`/v/${urlVersion}`, `/v/${window.apiVersion}`);
    if (!window.ddd) {
        window.location.assign(url);
    }
    return true;
}



export default url;
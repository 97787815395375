import { clsModel, fnCreate } from '@cls/clsModel'
import { hourline as hourlineapi, hourlist as api } from '@/app/api'
import { tariff as tarifflist, hourtype as hourtypelist, employee as employeelist} from '@app/list';
import string from '@lib/string'
import {date} from '@lib/date'

var modelName = "hourlist";
const fields = [
    "id", 
    "id_hourlist", 
    "hl_week", 
    "hl_year", 
    "lines",
    "id_employee", 
    "hl_completed", 
    "empl_workweek_minutes",
];

// The line data which we use to add a new line to the hourlist
// 
class clsCreateLineData {
    hl_week = null;
    hl_year = null;
    id_hourlist = null;
    _id_hour_type = null;

    _hr_start_time = null;
    _hr_end_time = null;
    hr_total_minutes = 0;
    hr_remark = null;
    
    id_tariff = null;

    _specify_start_end_time = true;

    id_project = null;
    pro_name = null;
    pro_number = null;
    id_project_chapter = null; 
    ch_name = null; 
    ch_rep = null;

    dayofweek = 0; // The year/week starts on monday already. This is the offset.
                   // so, monday is 0, sunday is 6.  

    get hr_start_time() {return this._hr_start_time;}
    get hr_end_time() {return this._hr_end_time;}
    set hr_start_time(v) {
        this._hr_start_time = v;
        this.calculateWorkingHours();
    }
    set hr_end_time(v) {
        this._hr_end_time = v;
        this.calculateWorkingHours();
    }
    calculateWorkingHours() {
        var mFrom = this._hr_start_time || 0;
        var mTo   = this._hr_end_time || 0;
        this.hr_total_minutes = Math.max(0, mTo - mFrom);
    }
    get isForProject() {
        if (!this.id_hour_type) {
            return false;
        }
        return !!hourtypelist.oneProp(this.id_hour_type, 'project')
    }

    get specify_start_end_time()  { return this._specify_start_end_time;} 
    set specify_start_end_time(v) { 
        this._specify_start_end_time = v;
        if (!v) {
            if (!this.id_tariff) {
                if (tarifflist?.list?.length) {
                    this.id_tariff = tarifflist.list[0].id;
                }
            }
        }
    } 

    get id_hour_type() {
        return this._id_hour_type;
    }
    set id_hour_type(v) {
        this._id_hour_type = v;
        if (!this.isForProject) {
            this.id_project = null;
            this.pro_name = null;
            this.pro_number = null;
            this.id_project_chapter = null; 
            this.ch_name = null; 
            this.ch_rep = null;
        }
    }

    // Get the date 
    yyww2Date(offset) {
        return date.isoYearWeekToDate(this.hl_year, this.hl_week, offset||0)
    }
    fmtDateShort(d) {
        if (!d) {
            return "";
        }
        if (d.getTime && !d.getTime()) {
            return "";
        }
        return date.fmt.custom(d, "DD MMM");
    }

//    yyww2DateShort(offset) {
//        var dt = this.yyww2Date(offset);
//        return date.fmt.custom("DD MMM");
//    }
    get mon() { return this.dayofweek == 0} set mon(x) { this.dayofweek = 0}
    get tue() { return this.dayofweek == 1} set tue(x) { this.dayofweek = 1}
    get wed() { return this.dayofweek == 2} set wed(x) { this.dayofweek = 2}
    get thu() { return this.dayofweek == 3} set thu(x) { this.dayofweek = 3}
    get fri() { return this.dayofweek == 4} set fri(x) { this.dayofweek = 4}
    get sat() { return this.dayofweek == 5} set sat(x) { this.dayofweek = 5}
    get sun() { return this.dayofweek == 6} set sun(x) { this.dayofweek = 6}
    get fmt_date_mon() { return this.fmtDateShort(this.yyww2Date(0)); }
    get fmt_date_tue() { return this.fmtDateShort(this.yyww2Date(1)); }
    get fmt_date_wed() { return this.fmtDateShort(this.yyww2Date(2)); }
    get fmt_date_thu() { return this.fmtDateShort(this.yyww2Date(3)); }
    get fmt_date_fri() { return this.fmtDateShort(this.yyww2Date(4)); }
    get fmt_date_sat() { return this.fmtDateShort(this.yyww2Date(5)); }
    get fmt_date_sun() { return this.fmtDateShort(this.yyww2Date(6)); }

    setData(hl_year, hl_week, hr_start_time, hr_end_time) {
        this.hl_week = hl_week;
        this.hl_year = hl_year;
        this.hr_start_time = hr_start_time;
        this.hr_end_time = hr_end_time;    
    }

    toJSON() {
        var model = {
            dayofweek: this.dayofweek, 
            hr_total_minutes: this.hr_total_minutes,
            id_hour_type: this.id_hour_type,
            id_tariff: this.id_tariff,
            id_project: this.id_project,
            id_project_chapter: this.id_project_chapter,
            hr_remark: this.hr_remark
        }
        if (this.specify_start_end_time) {
            model.hr_start_time = this.hr_start_time;
            model.hr_end_time = this.hr_end_time;
        }
        
        return model;
    }

}

class clsHourList extends clsModel {

    cline = new clsCreateLineData();

    id = null;
    hl_week = null;
    hl_year = null;
    lines = [];
    id_employee = null;           // display only

    hl_completed = null;          // 
    empl_workweek_minutes = null; // display only

    // Get the date 
    yyww2Date(offset) {
        return date.isoYearWeekToDate(this.hl_year, this.hl_week, offset||0)
    }
    get empl_name() {
        return employeelist.oneProp(this.id_employee, 'name', '-');
    }
  
    get totalMinutes() {
        return (this.lines||[]).reduce( (acc, line) => acc+(Number(line.hr_total_minutes)||0), 0)  
    }
    get week_name() {
        if (!this.hl_week) {
            return '-';
        }
        if (!this.hl_year) {
            return '-';
        }
        var w = string.right(`00${this.hl_week}`, 2);
        return `${this.hl_year}-${w}`
    }
    get date_rep(){
        if (!this.hl_week) {
            return '-';
        }
        if (!this.hl_year) {
            return '-';
        }
        
        var weekStart = this.yyww2Date(0);
        var weekEnd   = this.yyww2Date(6);
        if (weekStart.getMonth() == weekEnd.getMonth()) {
            // Same month
            return date.fmt.custom(weekStart, "DD -") + date.fmt.custom(weekEnd, 'DD MMMM YYYY');
        }
        if (weekStart.getYear() == weekEnd.getYear()) {
            // Different month, same year
            return date.fmt.custom(weekStart, "DD MMMM - ") + date.fmt.custom(weekEnd, 'DD MMMM YYYY');
        }
        // Different month, different year
        return date.fmt.custom(weekStart, "DD MMMM YYYY - ") + date.fmt.custom(weekEnd, 'DD MMMM YYYY');
    }

    // For any of the lines, is a tariff specified where the internal amount for the employee is 0? 
    get hasEmptyEmployeeTariff() {
        return !!(this.lines||[]).find( (line)=>!!line.tariff&&!line.tariff_amount_intern);
    }

    get modelRep() {
        return this.ht_name;
    }

    fill(data) {
        data = data ||{};
        this.cline.setData(data.hl_year, data.hl_week, data.empl_start_time_minutes, data.empl_end_time_minutes);
    
        return super.fill(data);
    }

    async removeLine(line) {
        if (!(this.lines||[]).find( (l) =>l == line)) {
            return;
        }
        this.isLoading = true;         
        try {
            var {data} = await hourlineapi.removeOne(line.id, {id_hourlist: this.id});
            this.lines = data.lines;    
        } finally {
            this.isLoading = false;
        }

    }

    /**
     * Add the filled data as a line in the registration lines 
     */
    async addLine() {

        var model = this.cline.toJSON();
        model.id_hourlist = this.id; 
        this.isLoading = true;         
        try {
            var {data} = await hourlineapi.create(model);
            this.lines = data.lines;    
        } finally {
            this.isLoading = false;
        }

    }

    /**
     * Archive this hourlist 
     */
    async archive() {
        this.isLoading = true;         
        try {
            var {data} = await api.archive(this.id);
            this.hl_completed = 1;
        } finally {
            this.isLoading = false;
        }
    }
    // Unarchive this hourlist
    async unArchive() {
        this.isLoading = true;         
        try {
            var {data} = await api.unArchive(this.id);
            this.hl_completed = 0;
        } finally {
            this.isLoading = false;
        }
    }

    get isArchived() {
        return !!this.hl_completed;
    }
    get disabled() {
        return super.disabled || this.isArchived;
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsHourList , 'clsHourList');

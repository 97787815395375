import eventbus from '@app/eventbus'
import * as date from '@lib/date'
import string from '@lib/string'
import * as numeric from '@lib/numeric'
import url from '@lib/url'
import constants from '@app/consts'
import feature from '@app/features'
import noty from '@lib/noty'
import * as list from '@app/list'
import routing from '@app/routing'
import * as settings from '@/app/settings'
import action from '@app/action'
import dlg from '@app/dlg'
import http from '@app/http'

import user from '@app/user'

/**
 * a helper which makes it a bit easier to 
 * troubleshoot things in the acceptance- or production environment. 
 */
class zDevHelper {
    is_initialized = false; 
    init() {
        if (this.is_initialized) {
            return;
        }
        eventbus.l.ga.on((x) => {
            window._zl = {
                date, 
                string,
                numeric,
                url,
                constants,
                feature,
                noty,
                list,
                routing,
                settings,                
                action,
                dlg
            };
            x = x ||"";
            x = `${x}xxx`;
            // Just a note about this, it is not that bad if the user is available for viewing or even manipulation. 
            // In the backend, all routes and data are protected. However, via this way we make it slightly more difficult
            // to mess around with the user data to see what happens in the frontend.
            // Anyone with a bit programmming knowledge can decode this with ease, but it requires effort, especially on production 
            // images.
            if (Number(`${x.charCodeAt(0)+2}${x.charCodeAt(1)+3}${x.charCodeAt(2)+5}`) == 111121127) {
                window._zl.user = user;
                window._zl.http = http;
            }
        })
        this.is_initialized = true;
    }    
}

var helper = new zDevHelper();

export default helper;
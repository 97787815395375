<template>
    <Dialog :dialog="dlg">
        <template v-slot:toolbar-left>
            
            <ToolbarField label="Status">
               <ActualArchivedStatusChip field="isArchived" :model="model"></ActualArchivedStatusChip>
            </ToolbarField>

            <ToolbarField class="ml-8" label="Medewerker">
                <span class="text-primary">{{model.empl_name||'-'}}</span>
            </ToolbarField>

            <ToolbarField class="ml-8" label="Week">
                <span class="text-primary">{{model.week_name||'-'}}</span>
            </ToolbarField>

            <ToolbarField class="ml-8" label="Datum">
                <span class="text-primary">{{model.date_rep||'-'}}</span>                
            </ToolbarField>

        </template>
        <template v-slot:toolbar-right>
            <ActionButton primary scmodel="model" v-if="!model.isArchived" ml action="hourlist.archive" icon="archive" @click="onArchive">Status Gereed</ActionButton>
            <ActionButton primary scmodel="model" v-else ml action="hourlist.unarchive" icon="unarchive" @click="onUnArchive">Maak actueel</ActionButton>            
        </template>

        <template v-slot:toolbar-menu>
                
        </template>        
    
        <Header :model="model"></Header>
        <Lines :model="model"></Lines>
        
        <DialogHourLine/> 

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/hours/hourlist'    

    import Dialog from '@shared/ui/dialogs/ActionDialogModal'
    import { ref, watch } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import ToolbarField from '@shared/ui/dialogs/ToolbarField'
    
    import Header from './HourListHeader'
    import Lines from './HourListLines'
    import ActualArchivedStatusChip from '@/ui/controls/ActualArchivedStatusChip'

    import ActionButton from '@controls/buttons/ActionButton'
    import appDlg from '@app/dlg'
    import action from '@app/action'
    import noty from '@shared/lib/noty'
    import eventbus from '@app/eventbus'
    import DialogHourLine from '@/ui/dialogs/hours/DialogHourLine'    

    const formHeader = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("hourlist", "Urenlijst", model, [formHeader], 
        {
            icon: 'hours',
            save: null,
            noteType: "hourlist_note",
            flagType: "hourlist_flag",

            keepOpen: true,
        }
    )).value; 


    async function nononoonArchive() {
        if (!await noty.tryConfirm("Weet u zeker dat u deze urenlijst in status 'Gereed' wilt zetten?<br><br>U kunt de urenlijst hierna terugvinden in het menu 'Gereed'.<br>Urenstaten in status 'Gereed' kunt u altijd weer actueel maken.")) {
            return;
        }
        if (!action.can('hourlist.archive')) {
            return;
        }
        if (model.disabled) { // Also, when generally disabled, do not save.
            return; 
        }
        if (!await dlg.onSave()) {
            return false;
        }
        try {
            await model.archive();
            eventbus.model.stats.refresh("hourlist");   
        } catch (e) { if (!e.canceled) { throw e;} }

    }
    async function onNoUnArchive() {
        if (!await noty.tryConfirm("Weet u zeker dat u deze urenlijst weer actueel wilt maken?<br><br>U kunt de urenlijst hierna terugvinden in het menu 'Open'.")) {
            return;
        }
        if (!action.can('hourlist.unarchive')) {
            return;
        }
        if (model.disabled) { // Also, when generally disabled, do not save.
            return; 
        }
        if (!await dlg.onSave()) {
            return false;
        }
        try {
            await model.unArchive();
            eventbus.model.stats.refresh("hourlist");   
        } catch (e) { if (!e.canceled) { throw e;} }

    }

    async function onArchive() {
//        if (!await dlg.onSave()) {
//            return false;
//        }
        if (!await noty.tryConfirm("Weet u zeker dat u deze urenlijst gereed wilt melden?<br><br>Na het gereed melden kunt geen wijzigingen meer aanbrengen.<br>U kunt een urenlijst altijd weer actueel maken.")) {
            return;
        }
        model.archive();
    }
    async function onUnArchive(line) {
        if (!await noty.tryConfirm("Weet u zeker dat u deze urenlijst weer actueel wilt maken?<br><br>Wanneer een urenlijst actueel is kunnen er veranderingen in worden aangebracht.")) {
            return;
        }
        model.unArchive();
    }

</script>


import clsApiPost from '@cls/clsApiPost'

class clsPeriodicSalesInvoiceApi extends clsApiPost {

    async examplePdf(id) {         
        return await this.postRaw(`examplepdf`, {id: id});  
    }
    async start(id) {         
        return await this.post(`start`, {id: id});  
    }
    async stop(id) {         
        return await this.post(`stop`, {id: id});  
    }


}

export default clsPeriodicSalesInvoiceApi;

//
// Usage: 
//  import action from '@app/action'
//

import clsAction from '@/app/clsAction'
import {ref} from 'vue'

let obj =  ref (new clsAction());

export default obj.value;
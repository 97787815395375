
<template>
  <v-app>
    <router-view/>

    <DialogAlert/>
    <DialogConfirm/>
    <DialogPrompt/>
    <DialogPromptPassword/>
    <DialogConfirmPassword/>
    <DialogChangePassword/>
    <DialogChangeUsername/>
    <DialogChangePersonName/>
    <DialogSetup2FA/>
    <Snackbar/>
    <PDFViewer/>
    <AppDialogs/>
    
  </v-app>
  
</template>

<script>

import eventbus from '@app/eventbus'
import DialogAlert from '@shared/ui/dialogs/DialogAlert.vue'

import DialogChangePassword from '@shared/ui/dialogs/DialogChangePassword.vue'
import DialogChangeUsername from '@shared/ui/dialogs/DialogChangeUsername.vue'
import DialogChangePersonName from '@shared/ui/dialogs/DialogChangePersonName.vue'

import DialogSetup2FA from '@shared/ui/dialogs/DialogSetup2FA.vue'
import DialogConfirm from '@shared/ui/dialogs/DialogConfirm.vue'
import DialogPrompt from '@shared/ui/dialogs/DialogPrompt.vue'
import DialogPromptPassword from '@shared/ui/dialogs/DialogPromptPassword.vue'

import DialogConfirmPassword from '@shared/ui/dialogs/DialogConfirmPassword.vue'
import Snackbar from '@shared/ui/snackbars/Snackbar.vue'
import PDFViewer from '@shared/ui/dialogs/DialogPDF'

import AppDialogs from './AppDialogs.vue'
import noty from '@shared/lib/noty'
import url from '@shared/lib/url'
// Import lists and settings as they must be created before the app starts.
import '@app/list';
import '@app/settings';
import zDevHelper from '@/app/zdevhelper';

// import '@/app/test';

export default {
    name: 'App',

    components: {
        DialogAlert, DialogConfirm, Snackbar, DialogConfirmPassword,DialogSetup2FA,
        DialogChangePassword, DialogChangeUsername, DialogChangePersonName, PDFViewer, DialogPrompt,DialogPromptPassword,
        AppDialogs
    },
    computed: {

    },
    methods: {

    },
    watch: {

    },    
    mounted() {

        // We will redirect instead of letting the router handle. 
        // The reason is that all 'internal' data is cleared as well this way.
        // See App.vue for redirect handling.
        eventbus.auth.loggedOut.on( async (para) => {
            var isExpired = para && para.scenario == 'expired';
            var isForceLogout = para && para.scenario == 'forcelogout';
            var isSilent = para && para.scenario == 'silentlogout';
            // Note: no action on isSilent.
            try {
                if (isExpired) {
                    await noty.confirm("Na een langere periode van inactiviteit wordt u automatisch uitgelogd.<br>U moet opnieuw inloggen.", 
                        {title: 'U bent uitgelogd', noCancel: true })            
                }
            }
            finally {
                if (isExpired || isForceLogout) {
                    url.redirect('/');
                }
            }
        });
        

        if (false == true) eventbus.api.version.on ( async (version, requestingUrl) => {
            // Note. We might be on the correct url and come here for the first time. 
            // In that case oldversion is true and we will try to change the url. 
            // This is fine. The url-versionhandler will only take action when the actual version in the url 
            // is different ffrom the api version.
            var bUsingOldVersion = window.apiVersion != version;
            var urlVersion = url.getPathPart('v');

            if (!window.apiVersion) {
                // When no apiVersion is set, this could mean that the url is just refreshed and this is the first call 
                // which updates the apiVersion. 
                // In this case, we check whether the url is matching the just retrieved apiversion.
                if (urlVersion && urlVersion == version) {
                    bUsingOldVersion = false;
                }
            }
            window.apiVersion = version;
            // If the current
            if (bUsingOldVersion) {
                // The api version 
                var silently = [
                    '/api/v',
                ];
                var exclude = [
                    '/api/user/load', 
                    '/logout', 
                    '/sanctum/csrf-cookie',
                    '/login',
                    '/api/data/load' 
                ];
                if (requestingUrl) {
                    var shouldExclude = exclude.find( (part) => requestingUrl.indexOf(part) >=0);
                    if (shouldExclude) {
                        console.log('Ignoring apiversion for ', requestingUrl);
                        return;
                    }
                    // When no version part is in the url yet, just reload with the right version.
                    var redirectSilently = !urlVersion || silently.find( (part) => requestingUrl.indexOf(part) >=0);
                    if (redirectSilently) {
                         url.redirectToVersionedUrl(redirectSilently);
                    }
                    else {
                        eventbus.dialog.open('versionupdate');
                    }
                }
            }
        })


        /////////////////////////////////////////////////////////////////////////////////

        eventbus.help.open.on ( (params) => {

            var q = [];
            q = params && params.helptopics;
            if (q) {
                // do nothing
            } else if (this.$route && this.$route.meta && this.$route.meta.help) {
                q = this.$route.meta.help;                        
            } else {
                q = ["relaties"]
            }
            if (window.zE) {
                window.zE('webWidget', 'helpCenter:setSuggestions', { labels: q }); 
                window.zE.activate({hideOnClose: true})
            } else {
                console.error('Zendesk widget is not loaded. Q: ', q);
            }
        });

        // One Handler for downloading documents
        // OBSOLETE
        // eventbus.document.download.on( (params) => {
        //     params = params || {};
        //     var id = params.id_file_document;
        //     if (!id) {
        //         console.error('Document without ID.');
        //         return;
        //     }
        //     api.documents.download(id);
        // });

        // Set an api error handler
        eventbus.api.error.on( (data) => {
            
            eventbus.dialog.alert( {
                title: data.title,
                body: data.error
            });
        });

        zDevHelper.init();
    },
    data() {
        return {
            mini: true,
            loading: "", 
        }
    }

}
</script>

<template>
    <v-dialog class="action-dialog action-dialog-modal" 
        :key="dialog.dlgKey"
        :content-class="cls" 
        persistent 
        scrollable
        fullscreen
        v-model="dialog.open" 
        :maxWidth="dialog.maxWidth" 
        @keydown="(evt) => onKeyDown(evt)">

            <v-card flat class="content-area" :loading="dialog.isLoading">

                <v-card-title flat class="pt-0 pb-0 px-4 modal-header">
                    <slot name="header-title">
                        <v-list class="dialog-header-title py-1">
                            <v-list-item class="pa-0">
                                <v-list-item-action class="icon-container ma-0 pa-3 mr-4" v-if="dialog.icon" >
                                    <slot name="header-icon">
                                        <Icon large color="primary" :type="dialog.icon"></Icon>
                                    </slot>
                                </v-list-item-action>                                            
                                <v-list-item-content>
                                <v-list-item-title >
                                    <slot name="header-maintitle">
                                        <h1 class="capitalize"><slot name="title">{{dialog.title}}</slot></h1>
                                    </slot>
                                </v-list-item-title>
                                <v-list-item-subtitle  v-if="!dialog.noSubTitle">
                                    <slot name="header-subtitle">
                                        <caption class="capitalize">{{dialog.subTitle}}</caption>
                                    </slot>
                                </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>                                    
                        </v-list>
                    </slot>
                    <v-spacer></v-spacer>                            
                    <div class="commands">
                        <slot name="header-pre-actions"></slot>

                        <slot name="header-action-flag">                                                         
                            <span v-if="dialog.flagType" class="ml-2">
                                <FlagMenu :action="`${dialog.flagType}.modify`" v-model="dialog.model.flag" @input="(v)=>dialog.setFlagValue(v)"></FlagMenu>
                            </span>
                        </slot>

                        <slot name="header-action-note">                                                         
                            <HeaderButton v-if="dialog.noteType" :action="`${dialog.noteType}.open`" caction="manday_note.open" :braction="`${dialog.noteType}.open`" dusk="btn-dialog-note" title="Open notitie" @click="() => dialog.onNote()" class="ml-2" small :class="dialog.noteCount?'icon-note-filled':'icon-note-empty'" :type="dialog.noteCount?'notefilled':'note'"/>
                        </slot>
                        <slot name="header-action-attachment">
                            <HeaderButton v-if="dialog.attachmentType" :action="`${dialog.attachmentType}.open`" dusk="btn-dialog-attachments" title="open bijlagen" @click="() => dialog.onAttachment()" class="ml-2" small :class="dialog.attachmentCount?'icon-attachment-filled':'icon-attachment-empty'" :type="dialog.attachmentCount?'attachmentsfilled':'attachments'"/>
                        </slot>
                        <slot name="header-post-actions">

                        </slot>
                        <slot name="header-action-menu">                                        
                            <v-menu v-if="$slots['header-menu']" bottom offset-y nudge-bottom="24px">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        <HeaderButton dusk="btn-dialog-menu-top" no-count class="ml-2" type="menu-horizontal"/>
                                    </span>
                                </template>
                                <v-list dense>
                                    <slot name="header-menu"></slot>
                                </v-list>
                            </v-menu>

                        </slot>
                        <slot name="header-close">
                            <HeaderButton dusk="btn-dialog-close" no-count class="ml-2" type="close" :disabled="dialog.isLoading" @click="dialog.onClose()"/>
                        </slot>
                        <slot name="header-post-actions"></slot>

                    </div>
                </v-card-title>                           
                
                <v-card-text  class="overflow-y-visible overflow-x-hidden pa-0 ">
                    <v-row dense>
                        <v-col>
                            <v-toolbar v-if="$slots['toolbar-left']||$slots['toolbar-right']"  dense flat class="py-2 px-4" style="height:auto;">
                                <slot name="toolbar-left"></slot>
                                <v-spacer></v-spacer>
                                <div class="mr-12" v-if="dialog.canBrowse&&dialog.tableName">
                                    <Browser :dialog="dialog" :model="dialog.model" :action="dialog.browseAction" :id="dialog.model.id" />
                                </div>
                                <span v-if="$slots['toolbar-menu']">
                                <v-menu v-if="$slots['toolbar-menu']" bottom offset-y rnudge-bottom="12px">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            <ActionButton class="ml-2" dusk="btn-dialog-menu-toolbar" icon-button-outlined icon="menu-horizontal">
                                            </ActionButton>
                                        </span>
                                    </template>
                                    <v-list dense>
                                        <slot name="toolbar-menu"></slot>
                                    </v-list>
                                </v-menu>
                                </span>
                                <slot name="toolbar-right"></slot>
                                <slot v-if="dialog.save" name="toolbar-save">
                                    <ActionButton v-if="showSave" dusk="btn-save" icon="save" :disabled="dialog.isLoading||dialog.disabled" main primary ml @click="dialog.onSave()">
                                        <slot name="toolbar-save-text">{{dialog.save}}</slot>
                                    </ActionButton>
                                </slot>
                            </v-toolbar>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>
                    <v-row class="box-shadow" dense>
                        <v-col class="pb-0">
                            <v-toolbar v-if="$slots['tabs-left']||$slots['tabs-right']" dense flat class="pt-0 px-8" style="height:auto;">
                                <slot name="tabs-left"></slot>
                                <v-spacer></v-spacer>
                                <slot name="tabs-right"></slot>
                            </v-toolbar>
                        </v-col>

                    </v-row>

                    <v-divider class="tab-divider mb-4" v-if="$slots['tabs-left']||$slots['tabs-right']"></v-divider>
                    
                    <div  :class="$slots['tabs-left']||$slots['tabs-right']?'pt-0':'pt-4'">
                        <v-container fluid class="dialog-content-container pt-0">
                            <v-row dense>
                                <v-col :sm="smBody" :lg="lgBody" :xl="xlBody">
                                    <div class="dialog-drawer">
                                        
                                    </div>
                                    <slot></slot>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-card-text>

        </v-card>

    </v-dialog>
</template>

<script setup>

    import Icon from '@controls/icons/Icon'
    import ActionControl from '@controls/ActionControl'
    import HeaderTitle from '@shared/ui/dialogs/HeaderTitle'
    import HeaderButton from '@shared/ui/dialogs/HeaderActionButton'
    import ActionButton from '@controls/buttons/ActionButton'
    import FlagMenu from "@controls/menu/DialogHeaderFlagMenu.vue";        
    import {computed} from 'vue'
    import Browser from '@datatable/components/Browser'

    const props = defineProps({
        dialog: {
            type: [Object]
        },
        // Show save works on top op the clsDialog 'save' property.
        // Use it e.g. to hide save button for specific statuses.
        showSave: {
            type: [Boolean],
            default: true
        },
    })

    const emit = defineEmits(['keydown'])

    const cls = computed({
        get() {
            return `${props.dialog.cls} action-dialog-modal`;
        },
    })

    // 
    // Note that the value may only be even, otherwise it is rounded up.
    //
    // dialog.sm --> 12: small devices, 12 columns
    //               6 : small devices, 3 - 6 - 3 columns  
    //
    // 
    // 
    // dialog.lg --> 12: large devices, 12 columns
    //               6 : large devices, 3 - 6 - 3 columns  
    // 

    function sideCols(n) {
        let p = Math.ceil(Number(n)/2)*2
        if (!p || Number(p) >=12) {
            return 0;
        }
        return (12-p)/2;
    }

    /**
     * Handle the onKeyDown event and then emit it to the parent
     */
    function onKeyDown(evt) {
        props.dialog.onKeyDown(evt);
        emit('keydown', evt);
    }

    // The column left and right
    const smSide = computed({
        get() {
            return sideCols(props.dialog.sm);
        },
    })
    const smBody = computed({
        get() {
            return 12 - (2*sideCols(props.dialog.sm))
        },
    })
    const lgSide = computed({
        get() {
            return sideCols(props.dialog.lg);
        },
    })
    const lgBody = computed({
        get() {
            return 12 - (2*sideCols(props.dialog.lg))
        },
    })
    const xlSide = computed({
        get() {
            return sideCols(props.dialog.xl);
        },
    })
    const xlBody = computed({
        get() {
            return 12 - (2*sideCols(props.dialog.xl))
        },
    })

    async function setFlag(v) {
    }

</script>
